/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
    --bs-blue: #00cfe8;
    --bs-indigo: #6610f2;
    --bs-purple: #b5151c;
    --bs-pink: #d63384;
    --bs-red: #ea5455;
    --bs-orange: #ff9f43;
    --bs-yellow: #ffc107;
    --bs-green: #28c76f;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #b8c2cc;
    --bs-gray-dark: #1e1e1e;
    --bs-gray-100: #babfc7;
    --bs-gray-200: #ededed;
    --bs-gray-300: #dae1e7;
    --bs-gray-400: #636363;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #b8c2cc;
    --bs-gray-700: #4e5154;
    --bs-gray-800: #1e1e1e;
    --bs-gray-900: #2a2e30;
    --bs-primary: #b5151c;
    --bs-secondary: #82868b;
    --bs-success: #28c76f;
    --bs-info: #00cfe8;
    --bs-warning: #ff9f43;
    --bs-danger: #ea5455;
    --bs-light: #f6f6f6;
    --bs-dark: #4b4b4b;
    --bs-primary-rgb:  181 , 21 , 29;
    --bs-secondary-rgb: 130, 134, 139;
    --bs-success-rgb: 40, 199, 111;
    --bs-info-rgb: 0, 207, 232;
    --bs-warning-rgb: 255, 159, 67;
    --bs-danger-rgb: 234, 84, 85;
    --bs-light-rgb: 246, 246, 246;
    --bs-dark-rgb: 75, 75, 75;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 34, 41, 47;
    --bs-body-rgb: 110, 107, 123;
    --bs-font-sans-serif: "Montserrat", Helvetica, Arial, serif;
    --bs-font-monospace: "Montserrat", Helvetica, Arial, serif;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.45;
    --bs-body-color: #6e6b7b;
    --bs-body-bg: #f8f8f8; }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box; }
  
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth; } }
  
  body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(34, 41, 47, 0); }
  
  hr {
    margin: 1rem 0;
    color: #ebe9f1;
    background-color: currentColor;
    border: 0;
    opacity: 1; }
  
  hr:not([size]) {
    height: 1px; }
  
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #5e5873; }
  
  h1, .h1 {
    font-size: calc(1.325rem + 0.9vw); }
  
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2rem; } }
  
  h2, .h2 {
    font-size: calc(1.2964rem + 0.5568vw); }
  
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.714rem; } }
  
  h3, .h3 {
    font-size: calc(1.275rem + 0.3vw); }
  
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }
  
  h4, .h4 {
    font-size: calc(1.2536rem + 0.0432vw); }
  
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.286rem; } }
  
  h5, .h5 {
    font-size: 1.07rem; }
  
  h6, .h6 {
    font-size: 1rem; }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem; }
  
  abbr[title],
  abbr[data-bs-original-title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none; }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  
  ol,
  ul {
    padding-left: 2rem; }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0; }
  
  dt {
    font-weight: 500; }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  
  blockquote {
    margin: 0 0 1rem; }
  
  b,
  strong {
    font-weight: 600; }
  
  small, .small {
    font-size: 0.857rem; }
  
  mark, .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  
  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline; }
  
  sub {
    bottom: -.25em; }
  
  sup {
    top: -.5em; }
  
  a {
    color: #b5151c;
    text-decoration: none; }
  
  a:hover {
    color: #b5151a;
    text-decoration: none; }
  
  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }
  
  pre,
  code,
  kbd,
  samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override; }
  
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 90%; }
  
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }
  
  code {
    font-size: 90%;
    color: #d63384;
    word-wrap: break-word; }
  
  a > code {
    color: inherit; }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 90%;
    color: #fff;
    background-color: #eee;
    border-radius: 0.25rem; }
  
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 500; }
  
  figure {
    margin: 0 0 1rem; }
  
  img,
  svg {
    vertical-align: middle; }
  
  table {
    caption-side: bottom;
    border-collapse: collapse; }
  
  caption {
    padding-top: 0.72rem;
    padding-bottom: 0.72rem;
    color: #b9b9c3;
    text-align: left; }
  
  th {
    text-align: inherit;
    text-align: -webkit-match-parent; }
  
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0; }
  
  label {
    display: inline-block; }
  
  button {
    border-radius: 0; }
  
  button:focus:not(:focus-visible) {
    outline: 0; }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  
  button,
  select {
    text-transform: none; }
  
  [role="button"] {
    cursor: pointer; }
  
  select {
    word-wrap: normal; }
  
  select:disabled {
    opacity: 1; }
  
  [list]::-webkit-calendar-picker-indicator {
    display: none; }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; }
  
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }
  
  ::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  
  textarea {
    resize: vertical; }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit; }
  
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  
  legend + * {
    clear: left; }
  
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0; }
  
  ::-webkit-inner-spin-button {
    height: auto; }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield; }
  
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  ::-webkit-search-decoration {
    -webkit-appearance: none; }
  
  ::-webkit-color-swatch-wrapper {
    padding: 0; }
  
  ::file-selector-button {
    font: inherit; }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  
  output {
    display: inline-block; }
  
  iframe {
    border: 0; }
  
  summary {
    display: list-item;
    cursor: pointer; }
  
  progress {
    vertical-align: baseline; }
  
  [hidden] {
    display: none !important; }
  
  .lead {
    font-size: 1.14rem;
    font-weight: 400; }
  
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
    font-weight: 400;
    line-height: 1.2; }
  
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 6rem; } }
  
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
    font-weight: 400;
    line-height: 1.2; }
  
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 5.5rem; } }
  
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 400;
    line-height: 1.2; }
  
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4.5rem; } }
  
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 400;
    line-height: 1.2; }
  
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }
  
  .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 400;
    line-height: 1.2; }
  
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }
  
  .display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 400;
    line-height: 1.2; }
  
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none; }
  
  .list-inline {
    padding-left: 0;
    list-style: none; }
  
  .list-inline-item {
    display: inline-block; }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }
  
  .initialism {
    font-size: 0.857rem;
    text-transform: uppercase; }
  
  .blockquote {
    margin-bottom: 0;
    font-size: 1.25rem; }
  
  .blockquote > :last-child {
    margin-bottom: 0; }
  
  .blockquote-footer {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.857rem;
    color: #b9b9c3; }
  
  .blockquote-footer::before {
    content: "\2014\00A0"; }
  
  .img-fluid {
    max-width: 100%;
    height: auto; }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #f8f8f8;
    border: 1px solid #dae1e7;
    border-radius: 0.357rem;
    max-width: 100%;
    height: auto; }
  
  .figure {
    display: inline-block; }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  
  .figure-caption {
    font-size: 0.857rem;
    color: #b8c2cc; }
  
  .container,
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1rem);
    padding-left: var(--bs-gutter-x, 1rem);
    margin-right: auto;
    margin-left: auto; }
  
  .container {
    max-width: 0; }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 576px; } }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 768px; } }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 992px; } }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1200px; } }
  
  @media (min-width: 1440px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      max-width: 1440px; } }
  
  .row {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5); }
  
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  
  .col {
    flex: 1 0 0%; }
  
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto; }
  
  .col-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  
  .col-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  
  .col-3 {
    flex: 0 0 auto;
    width: 25%; }
  
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  
  .col-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  
  .col-6 {
    flex: 0 0 auto;
    width: 50%; }
  
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  
  .col-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  
  .col-9 {
    flex: 0 0 auto;
    width: 75%; }
  
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  
  .col-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  
  .col-12 {
    flex: 0 0 auto;
    width: 100%; }
  
  .offset-1 {
    margin-left: 8.33333%; }
  
  .offset-2 {
    margin-left: 16.66667%; }
  
  .offset-3 {
    margin-left: 25%; }
  
  .offset-4 {
    margin-left: 33.33333%; }
  
  .offset-5 {
    margin-left: 41.66667%; }
  
  .offset-6 {
    margin-left: 50%; }
  
  .offset-7 {
    margin-left: 58.33333%; }
  
  .offset-8 {
    margin-left: 66.66667%; }
  
  .offset-9 {
    margin-left: 75%; }
  
  .offset-10 {
    margin-left: 83.33333%; }
  
  .offset-11 {
    margin-left: 91.66667%; }
  
  .g-0,
  .gx-0 {
    --bs-gutter-x: 0; }
  
  .g-0,
  .gy-0 {
    --bs-gutter-y: 0; }
  
  .g-25,
  .gx-25 {
    --bs-gutter-x: 0.25rem; }
  
  .g-25,
  .gy-25 {
    --bs-gutter-y: 0.25rem; }
  
  .g-50,
  .gx-50 {
    --bs-gutter-x: 0.5rem; }
  
  .g-50,
  .gy-50 {
    --bs-gutter-y: 0.5rem; }
  
  .g-75,
  .gx-75 {
    --bs-gutter-x: 0.75rem; }
  
  .g-75,
  .gy-75 {
    --bs-gutter-y: 0.75rem; }
  
  .g-1,
  .gx-1 {
    --bs-gutter-x: 1rem; }
  
  .g-1,
  .gy-1 {
    --bs-gutter-y: 1rem; }
  
  .g-2,
  .gx-2 {
    --bs-gutter-x: 1.5rem; }
  
  .g-2,
  .gy-2 {
    --bs-gutter-y: 1.5rem; }
  
  .g-3,
  .gx-3 {
    --bs-gutter-x: 3rem; }
  
  .g-3,
  .gy-3 {
    --bs-gutter-y: 3rem; }
  
  .g-4,
  .gx-4 {
    --bs-gutter-x: 3.5rem; }
  
  .g-4,
  .gy-4 {
    --bs-gutter-y: 3.5rem; }
  
  .g-5,
  .gx-5 {
    --bs-gutter-x: 4rem; }
  
  .g-5,
  .gy-5 {
    --bs-gutter-y: 4rem; }
  
  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%; }
    .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-sm-0 {
      margin-left: 0; }
    .offset-sm-1 {
      margin-left: 8.33333%; }
    .offset-sm-2 {
      margin-left: 16.66667%; }
    .offset-sm-3 {
      margin-left: 25%; }
    .offset-sm-4 {
      margin-left: 33.33333%; }
    .offset-sm-5 {
      margin-left: 41.66667%; }
    .offset-sm-6 {
      margin-left: 50%; }
    .offset-sm-7 {
      margin-left: 58.33333%; }
    .offset-sm-8 {
      margin-left: 66.66667%; }
    .offset-sm-9 {
      margin-left: 75%; }
    .offset-sm-10 {
      margin-left: 83.33333%; }
    .offset-sm-11 {
      margin-left: 91.66667%; }
    .g-sm-0,
    .gx-sm-0 {
      --bs-gutter-x: 0; }
    .g-sm-0,
    .gy-sm-0 {
      --bs-gutter-y: 0; }
    .g-sm-25,
    .gx-sm-25 {
      --bs-gutter-x: 0.25rem; }
    .g-sm-25,
    .gy-sm-25 {
      --bs-gutter-y: 0.25rem; }
    .g-sm-50,
    .gx-sm-50 {
      --bs-gutter-x: 0.5rem; }
    .g-sm-50,
    .gy-sm-50 {
      --bs-gutter-y: 0.5rem; }
    .g-sm-75,
    .gx-sm-75 {
      --bs-gutter-x: 0.75rem; }
    .g-sm-75,
    .gy-sm-75 {
      --bs-gutter-y: 0.75rem; }
    .g-sm-1,
    .gx-sm-1 {
      --bs-gutter-x: 1rem; }
    .g-sm-1,
    .gy-sm-1 {
      --bs-gutter-y: 1rem; }
    .g-sm-2,
    .gx-sm-2 {
      --bs-gutter-x: 1.5rem; }
    .g-sm-2,
    .gy-sm-2 {
      --bs-gutter-y: 1.5rem; }
    .g-sm-3,
    .gx-sm-3 {
      --bs-gutter-x: 3rem; }
    .g-sm-3,
    .gy-sm-3 {
      --bs-gutter-y: 3rem; }
    .g-sm-4,
    .gx-sm-4 {
      --bs-gutter-x: 3.5rem; }
    .g-sm-4,
    .gy-sm-4 {
      --bs-gutter-y: 3.5rem; }
    .g-sm-5,
    .gx-sm-5 {
      --bs-gutter-x: 4rem; }
    .g-sm-5,
    .gy-sm-5 {
      --bs-gutter-y: 4rem; } }
  
  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%; }
    .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-md-0 {
      margin-left: 0; }
    .offset-md-1 {
      margin-left: 8.33333%; }
    .offset-md-2 {
      margin-left: 16.66667%; }
    .offset-md-3 {
      margin-left: 25%; }
    .offset-md-4 {
      margin-left: 33.33333%; }
    .offset-md-5 {
      margin-left: 41.66667%; }
    .offset-md-6 {
      margin-left: 50%; }
    .offset-md-7 {
      margin-left: 58.33333%; }
    .offset-md-8 {
      margin-left: 66.66667%; }
    .offset-md-9 {
      margin-left: 75%; }
    .offset-md-10 {
      margin-left: 83.33333%; }
    .offset-md-11 {
      margin-left: 91.66667%; }
    .g-md-0,
    .gx-md-0 {
      --bs-gutter-x: 0; }
    .g-md-0,
    .gy-md-0 {
      --bs-gutter-y: 0; }
    .g-md-25,
    .gx-md-25 {
      --bs-gutter-x: 0.25rem; }
    .g-md-25,
    .gy-md-25 {
      --bs-gutter-y: 0.25rem; }
    .g-md-50,
    .gx-md-50 {
      --bs-gutter-x: 0.5rem; }
    .g-md-50,
    .gy-md-50 {
      --bs-gutter-y: 0.5rem; }
    .g-md-75,
    .gx-md-75 {
      --bs-gutter-x: 0.75rem; }
    .g-md-75,
    .gy-md-75 {
      --bs-gutter-y: 0.75rem; }
    .g-md-1,
    .gx-md-1 {
      --bs-gutter-x: 1rem; }
    .g-md-1,
    .gy-md-1 {
      --bs-gutter-y: 1rem; }
    .g-md-2,
    .gx-md-2 {
      --bs-gutter-x: 1.5rem; }
    .g-md-2,
    .gy-md-2 {
      --bs-gutter-y: 1.5rem; }
    .g-md-3,
    .gx-md-3 {
      --bs-gutter-x: 3rem; }
    .g-md-3,
    .gy-md-3 {
      --bs-gutter-y: 3rem; }
    .g-md-4,
    .gx-md-4 {
      --bs-gutter-x: 3.5rem; }
    .g-md-4,
    .gy-md-4 {
      --bs-gutter-y: 3.5rem; }
    .g-md-5,
    .gx-md-5 {
      --bs-gutter-x: 4rem; }
    .g-md-5,
    .gy-md-5 {
      --bs-gutter-y: 4rem; } }
  
  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%; }
    .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-lg-0 {
      margin-left: 0; }
    .offset-lg-1 {
      margin-left: 8.33333%; }
    .offset-lg-2 {
      margin-left: 16.66667%; }
    .offset-lg-3 {
      margin-left: 25%; }
    .offset-lg-4 {
      margin-left: 33.33333%; }
    .offset-lg-5 {
      margin-left: 41.66667%; }
    .offset-lg-6 {
      margin-left: 50%; }
    .offset-lg-7 {
      margin-left: 58.33333%; }
    .offset-lg-8 {
      margin-left: 66.66667%; }
    .offset-lg-9 {
      margin-left: 75%; }
    .offset-lg-10 {
      margin-left: 83.33333%; }
    .offset-lg-11 {
      margin-left: 91.66667%; }
    .g-lg-0,
    .gx-lg-0 {
      --bs-gutter-x: 0; }
    .g-lg-0,
    .gy-lg-0 {
      --bs-gutter-y: 0; }
    .g-lg-25,
    .gx-lg-25 {
      --bs-gutter-x: 0.25rem; }
    .g-lg-25,
    .gy-lg-25 {
      --bs-gutter-y: 0.25rem; }
    .g-lg-50,
    .gx-lg-50 {
      --bs-gutter-x: 0.5rem; }
    .g-lg-50,
    .gy-lg-50 {
      --bs-gutter-y: 0.5rem; }
    .g-lg-75,
    .gx-lg-75 {
      --bs-gutter-x: 0.75rem; }
    .g-lg-75,
    .gy-lg-75 {
      --bs-gutter-y: 0.75rem; }
    .g-lg-1,
    .gx-lg-1 {
      --bs-gutter-x: 1rem; }
    .g-lg-1,
    .gy-lg-1 {
      --bs-gutter-y: 1rem; }
    .g-lg-2,
    .gx-lg-2 {
      --bs-gutter-x: 1.5rem; }
    .g-lg-2,
    .gy-lg-2 {
      --bs-gutter-y: 1.5rem; }
    .g-lg-3,
    .gx-lg-3 {
      --bs-gutter-x: 3rem; }
    .g-lg-3,
    .gy-lg-3 {
      --bs-gutter-y: 3rem; }
    .g-lg-4,
    .gx-lg-4 {
      --bs-gutter-x: 3.5rem; }
    .g-lg-4,
    .gy-lg-4 {
      --bs-gutter-y: 3.5rem; }
    .g-lg-5,
    .gx-lg-5 {
      --bs-gutter-x: 4rem; }
    .g-lg-5,
    .gy-lg-5 {
      --bs-gutter-y: 4rem; } }
  
  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%; }
    .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-xl-0 {
      margin-left: 0; }
    .offset-xl-1 {
      margin-left: 8.33333%; }
    .offset-xl-2 {
      margin-left: 16.66667%; }
    .offset-xl-3 {
      margin-left: 25%; }
    .offset-xl-4 {
      margin-left: 33.33333%; }
    .offset-xl-5 {
      margin-left: 41.66667%; }
    .offset-xl-6 {
      margin-left: 50%; }
    .offset-xl-7 {
      margin-left: 58.33333%; }
    .offset-xl-8 {
      margin-left: 66.66667%; }
    .offset-xl-9 {
      margin-left: 75%; }
    .offset-xl-10 {
      margin-left: 83.33333%; }
    .offset-xl-11 {
      margin-left: 91.66667%; }
    .g-xl-0,
    .gx-xl-0 {
      --bs-gutter-x: 0; }
    .g-xl-0,
    .gy-xl-0 {
      --bs-gutter-y: 0; }
    .g-xl-25,
    .gx-xl-25 {
      --bs-gutter-x: 0.25rem; }
    .g-xl-25,
    .gy-xl-25 {
      --bs-gutter-y: 0.25rem; }
    .g-xl-50,
    .gx-xl-50 {
      --bs-gutter-x: 0.5rem; }
    .g-xl-50,
    .gy-xl-50 {
      --bs-gutter-y: 0.5rem; }
    .g-xl-75,
    .gx-xl-75 {
      --bs-gutter-x: 0.75rem; }
    .g-xl-75,
    .gy-xl-75 {
      --bs-gutter-y: 0.75rem; }
    .g-xl-1,
    .gx-xl-1 {
      --bs-gutter-x: 1rem; }
    .g-xl-1,
    .gy-xl-1 {
      --bs-gutter-y: 1rem; }
    .g-xl-2,
    .gx-xl-2 {
      --bs-gutter-x: 1.5rem; }
    .g-xl-2,
    .gy-xl-2 {
      --bs-gutter-y: 1.5rem; }
    .g-xl-3,
    .gx-xl-3 {
      --bs-gutter-x: 3rem; }
    .g-xl-3,
    .gy-xl-3 {
      --bs-gutter-y: 3rem; }
    .g-xl-4,
    .gx-xl-4 {
      --bs-gutter-x: 3.5rem; }
    .g-xl-4,
    .gy-xl-4 {
      --bs-gutter-y: 3.5rem; }
    .g-xl-5,
    .gx-xl-5 {
      --bs-gutter-x: 4rem; }
    .g-xl-5,
    .gy-xl-5 {
      --bs-gutter-y: 4rem; } }
  
  @media (min-width: 1440px) {
    .col-xxl {
      flex: 1 0 0%; }
    .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-xxl-0 {
      margin-left: 0; }
    .offset-xxl-1 {
      margin-left: 8.33333%; }
    .offset-xxl-2 {
      margin-left: 16.66667%; }
    .offset-xxl-3 {
      margin-left: 25%; }
    .offset-xxl-4 {
      margin-left: 33.33333%; }
    .offset-xxl-5 {
      margin-left: 41.66667%; }
    .offset-xxl-6 {
      margin-left: 50%; }
    .offset-xxl-7 {
      margin-left: 58.33333%; }
    .offset-xxl-8 {
      margin-left: 66.66667%; }
    .offset-xxl-9 {
      margin-left: 75%; }
    .offset-xxl-10 {
      margin-left: 83.33333%; }
    .offset-xxl-11 {
      margin-left: 91.66667%; }
    .g-xxl-0,
    .gx-xxl-0 {
      --bs-gutter-x: 0; }
    .g-xxl-0,
    .gy-xxl-0 {
      --bs-gutter-y: 0; }
    .g-xxl-25,
    .gx-xxl-25 {
      --bs-gutter-x: 0.25rem; }
    .g-xxl-25,
    .gy-xxl-25 {
      --bs-gutter-y: 0.25rem; }
    .g-xxl-50,
    .gx-xxl-50 {
      --bs-gutter-x: 0.5rem; }
    .g-xxl-50,
    .gy-xxl-50 {
      --bs-gutter-y: 0.5rem; }
    .g-xxl-75,
    .gx-xxl-75 {
      --bs-gutter-x: 0.75rem; }
    .g-xxl-75,
    .gy-xxl-75 {
      --bs-gutter-y: 0.75rem; }
    .g-xxl-1,
    .gx-xxl-1 {
      --bs-gutter-x: 1rem; }
    .g-xxl-1,
    .gy-xxl-1 {
      --bs-gutter-y: 1rem; }
    .g-xxl-2,
    .gx-xxl-2 {
      --bs-gutter-x: 1.5rem; }
    .g-xxl-2,
    .gy-xxl-2 {
      --bs-gutter-y: 1.5rem; }
    .g-xxl-3,
    .gx-xxl-3 {
      --bs-gutter-x: 3rem; }
    .g-xxl-3,
    .gy-xxl-3 {
      --bs-gutter-y: 3rem; }
    .g-xxl-4,
    .gx-xxl-4 {
      --bs-gutter-x: 3.5rem; }
    .g-xxl-4,
    .gy-xxl-4 {
      --bs-gutter-y: 3.5rem; }
    .g-xxl-5,
    .gx-xxl-5 {
      --bs-gutter-x: 4rem; }
    .g-xxl-5,
    .gy-xxl-5 {
      --bs-gutter-y: 4rem; } }
  
  .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #6e6b7b;
    --bs-table-striped-bg: #fafafc;
    --bs-table-active-color: #6e6b7b;
    --bs-table-active-bg: rgba(34, 41, 47, 0.1);
    --bs-table-hover-color: #6e6b7b;
    --bs-table-hover-bg: #f6f6f9;
    width: 100%;
    margin-bottom: 1rem;
    color: #6e6b7b;
    vertical-align: middle;
    border-color: #ebe9f1; }
  
  .table > :not(caption) > * > * {
    padding: 0.72rem 2rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  
  .table > tbody {
    vertical-align: inherit; }
  
  .table > thead {
    vertical-align: bottom; }
  
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #ebe9f1; }
  
  .caption-top {
    caption-side: top; }
  
  .table-sm > :not(caption) > * > * {
    padding: 0.3rem 0.5rem; }
  
  .table-bordered > :not(caption) > * {
    border-width: 1px 0; }
  
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }
  
  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0; }
  
  .table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color); }
  
  .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color); }
  
  .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color); }
  
  .table-primary {
    --bs-table-bg: #e3e1fc;
    --bs-table-striped-bg: #d9d8f2;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #d0cfe8;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #d5d3ed;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #d0cfe8; }
  
  .table-secondary {
    --bs-table-bg: #e6e7e8;
    --bs-table-striped-bg: #dcdedf;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #d2d4d6;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #d7d9da;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #d2d4d6; }
  
  .table-success {
    --bs-table-bg: #d4f4e2;
    --bs-table-striped-bg: #cbead9;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #c2e0d0;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #c7e5d5;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #c2e0d0; }
  
  .table-info {
    --bs-table-bg: #ccf5fa;
    --bs-table-striped-bg: #c4ebf0;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #bbe1e6;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #bfe6eb;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #bbe1e6; }
  
  .table-warning {
    --bs-table-bg: #ffecd9;
    --bs-table-striped-bg: #f4e2d1;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #e9d9c8;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #eeddcc;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #e9d9c8; }
  
  .table-danger {
    --bs-table-bg: #fbdddd;
    --bs-table-striped-bg: #f0d4d4;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #e5cbcc;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #ebd0d0;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #e5cbcc; }
  
  .table-light {
    --bs-table-bg: #f6f6f6;
    --bs-table-striped-bg: #ebecec;
    --bs-table-striped-color: #22292f;
    --bs-table-active-bg: #e1e2e2;
    --bs-table-active-color: #22292f;
    --bs-table-hover-bg: #e6e7e7;
    --bs-table-hover-color: #22292f;
    color: #22292f;
    border-color: #e1e2e2; }
  
  .table-dark {
    --bs-table-bg: #4b4b4b;
    --bs-table-striped-bg: #545454;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #5d5d5d;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #595959;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #5d5d5d; }
  
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 1439.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  .form-label {
    margin-bottom: 0.2857rem;
    font-size: 0.857rem;
    color: #5e5873; }
  
  .col-form-label {
    padding-top: calc(0.571rem + 1px);
    padding-bottom: calc(0.571rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.45;
    color: #5e5873; }
  
  .col-form-label-lg {
    padding-top: calc(0.75rem + 1px);
    padding-bottom: calc(0.75rem + 1px);
    font-size: 1.143rem; }
  
  .col-form-label-sm {
    padding-top: calc(0.188rem + 1px);
    padding-bottom: calc(0.188rem + 1px);
    font-size: 0.857rem; }
  
  .form-text {
    margin-top: 0.25rem;
    font-size: 0.857rem;
    color: #b9b9c3; }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.571rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    appearance: none;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  
  .form-control[type="file"] {
    overflow: hidden; }
  
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer; }
  
  .form-control:focus {
    color: #6e6b7b;
    background-color: #fff;
    border-color: #b5151c;
    outline: 0;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1); }
  
  .form-control::-webkit-date-and-time-value {
    height: 1.45em; }
  
  .form-control::placeholder {
    color: #b9b9c3;
    opacity: 1; }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #efefef;
    opacity: 1; }
  
  .form-control::file-selector-button {
    padding: 0.571rem 1rem;
    margin: -0.571rem -1rem;
    margin-inline-end: 1rem;
    color: #6e6b7b;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
      transition: none; } }
  
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2; }
  
  .form-control::-webkit-file-upload-button {
    padding: 0.571rem 1rem;
    margin: -0.571rem -1rem;
    margin-inline-end: 1rem;
    color: #6e6b7b;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      transition: none; } }
  
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2; }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.571rem 0;
    margin-bottom: 0;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
  
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }
  
  .form-control-sm {
    min-height: 2.142rem;
    padding: 0.188rem 0.857rem;
    font-size: 0.857rem;
    border-radius: 0.357rem; }
  
  .form-control-sm::file-selector-button {
    padding: 0.188rem 0.857rem;
    margin: -0.188rem -0.857rem;
    margin-inline-end: 0.857rem; }
  
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.188rem 0.857rem;
    margin: -0.188rem -0.857rem;
    margin-inline-end: 0.857rem; }
  
  .form-control-lg {
    min-height: 3.2857rem;
    padding: 0.75rem 1.143rem;
    font-size: 1.143rem;
    border-radius: 0.357rem; }
  
  .form-control-lg::file-selector-button {
    padding: 0.75rem 1.143rem;
    margin: -0.75rem -1.143rem;
    margin-inline-end: 1.143rem; }
  
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.75rem 1.143rem;
    margin: -0.75rem -1.143rem;
    margin-inline-end: 1.143rem; }
  
  textarea.form-control {
    min-height: 2.714rem; }
  
  textarea.form-control-sm {
    min-height: 2.142rem; }
  
  textarea.form-control-lg {
    min-height: 3.2857rem; }
  
  .form-control-color {
    width: 3rem;
    height: auto;
    padding: 0.571rem; }
  
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  
  .form-control-color::-moz-color-swatch {
    height: 1.45em;
    border-radius: 0.357rem; }
  
  .form-control-color::-webkit-color-swatch {
    height: 1.45em;
    border-radius: 0.357rem; }
  
  .form-select {
    display: block;
    width: 100%;
    padding: 0.571rem 1rem 0.571rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 15px 14px;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  
  .form-select:focus {
    border-color: #b5151c;
    outline: 0;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1); }
  
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none; }
  
  .form-select:disabled {
    background-color: #efefef; }
  
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #6e6b7b; }
  
  .form-select-sm {
    padding-top: 0.188rem;
    padding-bottom: 0.188rem;
    padding-left: 0.857rem;
    font-size: 0.857rem; }
  
  .form-select-lg {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.143rem;
    font-size: 1.143rem; }
  
  .form-check {
    display: block;
    min-height: 1.45rem;
    padding-left: 1.785rem;
    margin-bottom: 0; }
  
  .form-check .form-check-input {
    float: left;
    margin-left: -1.785rem; }
  
  .form-check-input {
    width: 1.285rem;
    height: 1.285rem;
    margin-top: 0.0825rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #d8d6de;
    appearance: none;
    color-adjust: exact; }
  
  .form-check-input[type="checkbox"] {
    border-radius: 3px; }
  
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  
  .form-check-input:active {
    filter: brightness(90%); }
  
  .form-check-input:focus {
    border-color: #b5151c;
    outline: 0;
    box-shadow: 0 2px 4px 0 rgba(115, 103, 240, 0.4); }
  
  .form-check-input:checked {
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3e%3cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3e%3c/svg%3e"); }
  
  .form-check-input:checked[type="radio"] {
    background-image: none; }
  
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #b5151c;
    border-color: #b5151c;
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus'%3e%3cline x1='5' y1='12' x2='19' y2='12'%3e%3c/line%3e%3c/svg%3e"); }
  
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }
  
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.65; }
  
  .form-check-label {
    cursor: pointer; }
  
  .form-switch {
    padding-left: 3.5rem; }
  
  .form-switch .form-check-input {
    width: 3rem;
    margin-left: -3.5rem;
    background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 3rem;
    transition: background-position 0.15s ease-in-out, background-color 0.1s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
      transition: none; } }
  
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e"); }
  
  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e"); }
  
  .form-check-inline {
    display: inline-block;
    margin-right: 1rem; }
  
  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }
  
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }
  
  .form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    appearance: none; }
  
  .form-range:focus {
    outline: 0; }
  
  .form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #f8f8f8, 0 3px 10px 0 rgba(34, 41, 47, 0.1); }
  
  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f8f8f8, 0 3px 10px 0 rgba(34, 41, 47, 0.1); }
  
  .form-range::-moz-focus-outer {
    border: 0; }
  
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #b5151c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      transition: none; } }
  
  .form-range::-webkit-slider-thumb:active {
    background-color: #d5d1fb; }
  
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dae1e7;
    border-color: transparent;
    border-radius: 1rem; }
  
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #b5151c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      transition: none; } }
  
  .form-range::-moz-range-thumb:active {
    background-color: #d5d1fb; }
  
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dae1e7;
    border-color: transparent;
    border-radius: 1rem; }
  
  .form-range:disabled {
    pointer-events: none; }
  
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  
  .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  
  .form-floating {
    position: relative; }
  
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(2.57rem + 2px);
    line-height: 1.25; }
  
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0.571rem 1rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
  
  @media (prefers-reduced-motion: reduce) {
    .form-floating > label {
      transition: none; } }
  
  .form-floating > .form-control {
    padding: 0.571rem 1rem; }
  
  .form-floating > .form-control::placeholder {
    color: transparent; }
  
  .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 0.571rem;
    padding-bottom: 0.571rem; }
  
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 0.571rem;
    padding-bottom: 0.571rem; }
  
  .form-floating > .form-select {
    padding-top: 0.571rem;
    padding-bottom: 0.571rem; }
  
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.9;
    transform: scale(0.85) translateY(-2.25rem) translateX(-0.85rem); }
  
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.9;
    transform: scale(0.85) translateY(-2.25rem) translateX(-0.85rem); }
  
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
  
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  
  .input-group .btn {
    position: relative;
    z-index: 2; }
  
  .input-group .btn:focus {
    z-index: 3; }
  
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.571rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem; }
  
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    padding: 0.75rem 1.143rem;
    font-size: 1.143rem;
    border-radius: 0.357rem; }
  
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    padding: 0.188rem 0.857rem;
    font-size: 0.857rem;
    border-radius: 0.357rem; }
  
  .input-group-lg > .form-select,
  .input-group-sm > .form-select {
    padding-right: 2rem; }
  
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.857rem;
    color: #28c76f; }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.4rem 0.775rem;
    margin-top: .1rem;
    font-size: 0.857rem;
    color: #fff;
    background-color: #28c76f;
    border-radius: 0.358rem; }
  
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip,
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip {
    display: block; }
  
  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #28c76f;
    padding-right: calc(1.45em + 1.142rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3625em + 0.2855rem) center;
    background-size: calc(0.725em + 0.571rem) calc(0.725em + 0.571rem); }
  
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28c76f;
    box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.25); }
  
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.45em + 1.142rem);
    background-position: top calc(0.3625em + 0.2855rem) right calc(0.3625em + 0.2855rem); }
  
  .was-validated .form-select:valid, .form-select.is-valid {
    border-color: #28c76f; }
  
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 3.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 1rem;
    background-size: 15px 14px, calc(0.725em + 0.571rem) calc(0.725em + 0.571rem); }
  
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #28c76f;
    box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.25); }
  
  .was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #28c76f; }
  
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #28c76f; }
  
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.25); }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28c76f; }
  
  .form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: .5em; }
  
  .was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
  .input-group .form-select:valid,
  .input-group .form-select.is-valid {
    z-index: 1; }
  
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.857rem;
    color: #ea5455; }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.4rem 0.775rem;
    margin-top: .1rem;
    font-size: 0.857rem;
    color: #fff;
    background-color: #ea5455;
    border-radius: 0.358rem; }
  
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block; }
  
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #ea5455;
    padding-right: calc(1.45em + 1.142rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ea5455'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3625em + 0.2855rem) center;
    background-size: calc(0.725em + 0.571rem) calc(0.725em + 0.571rem); }
  
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ea5455;
    box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.25); }
  
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.45em + 1.142rem);
    background-position: top calc(0.3625em + 0.2855rem) right calc(0.3625em + 0.2855rem); }
  
  .was-validated .form-select:invalid, .form-select.is-invalid {
    border-color: #ea5455; }
  
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 3.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ea5455'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 1rem;
    background-size: 15px 14px, calc(0.725em + 0.571rem) calc(0.725em + 0.571rem); }
  
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #ea5455;
    box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.25); }
  
  .was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #ea5455; }
  
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #ea5455; }
  
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.25); }
  
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ea5455; }
  
  .form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: .5em; }
  
  .was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
  .input-group .form-select:invalid,
  .input-group .form-select.is-invalid {
    z-index: 2; }
  
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    color: #6e6b7b;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.786rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.358rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; }
  
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  
  .btn:hover {
    color: #6e6b7b; }
  
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(115, 103, 240, 0.25); }
  
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }
  
  .btn-primary {
    color: #fff;
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #6258cc;
    border-color: #5c52c0; }
  
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #6258cc;
    border-color: #5c52c0;
    box-shadow: 0 0 0 0.25rem rgba(136, 126, 242, 0.5); }
  
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5c52c0;
    border-color: #564db4; }
  
  .btn-check:checked + .btn-primary:focus,
  .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(136, 126, 242, 0.5); }
  
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .btn-secondary {
    color: #fff;
    background-color: #82868b;
    border-color: #82868b; }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #6f7276;
    border-color: #686b6f; }
  
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #6f7276;
    border-color: #686b6f;
    box-shadow: 0 0 0 0.25rem rgba(149, 152, 156, 0.5); }
  
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #686b6f;
    border-color: #626568; }
  
  .btn-check:checked + .btn-secondary:focus,
  .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(149, 152, 156, 0.5); }
  
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #82868b;
    border-color: #82868b; }
  
  .btn-success {
    color: #22292f;
    background-color: #28c76f;
    border-color: #28c76f; }
  
  .btn-success:hover {
    color: #22292f;
    background-color: #48cf85;
    border-color: #3ecd7d; }
  
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #22292f;
    background-color: #48cf85;
    border-color: #3ecd7d;
    box-shadow: 0 0 0 0.25rem rgba(39, 175, 101, 0.5); }
  
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #22292f;
    background-color: #53d28c;
    border-color: #3ecd7d; }
  
  .btn-check:checked + .btn-success:focus,
  .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(39, 175, 101, 0.5); }
  
  .btn-success:disabled, .btn-success.disabled {
    color: #22292f;
    background-color: #28c76f;
    border-color: #28c76f; }
  
  .btn-info {
    color: #22292f;
    background-color: #00cfe8;
    border-color: #00cfe8; }
  
  .btn-info:hover {
    color: #22292f;
    background-color: #26d6eb;
    border-color: #1ad4ea; }
  
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #22292f;
    background-color: #26d6eb;
    border-color: #1ad4ea;
    box-shadow: 0 0 0 0.25rem rgba(5, 182, 204, 0.5); }
  
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #22292f;
    background-color: #33d9ed;
    border-color: #1ad4ea; }
  
  .btn-check:checked + .btn-info:focus,
  .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(5, 182, 204, 0.5); }
  
  .btn-info:disabled, .btn-info.disabled {
    color: #22292f;
    background-color: #00cfe8;
    border-color: #00cfe8; }
  
  .btn-warning {
    color: #22292f;
    background-color: #ff9f43;
    border-color: #ff9f43; }
  
  .btn-warning:hover {
    color: #22292f;
    background-color: #ffad5f;
    border-color: #ffa956; }
  
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #22292f;
    background-color: #ffad5f;
    border-color: #ffa956;
    box-shadow: 0 0 0 0.25rem rgba(222, 141, 64, 0.5); }
  
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #22292f;
    background-color: #ffb269;
    border-color: #ffa956; }
  
  .btn-check:checked + .btn-warning:focus,
  .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 141, 64, 0.5); }
  
  .btn-warning:disabled, .btn-warning.disabled {
    color: #22292f;
    background-color: #ff9f43;
    border-color: #ff9f43; }
  
  .btn-danger {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
  
  .btn-danger:hover {
    color: #fff;
    background-color: #c74748;
    border-color: #bb4344; }
  
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #c74748;
    border-color: #bb4344;
    box-shadow: 0 0 0 0.25rem rgba(237, 110, 111, 0.5); }
  
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bb4344;
    border-color: #b03f40; }
  
  .btn-check:checked + .btn-danger:focus,
  .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(237, 110, 111, 0.5); }
  
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
  
  .btn-light {
    color: #22292f;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  
  .btn-light:hover {
    color: #22292f;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
  
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #22292f;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    box-shadow: 0 0 0 0.25rem rgba(214, 215, 216, 0.5); }
  
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #22292f;
    background-color: #f8f8f8;
    border-color: #f7f7f7; }
  
  .btn-check:checked + .btn-light:focus,
  .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(214, 215, 216, 0.5); }
  
  .btn-light:disabled, .btn-light.disabled {
    color: #22292f;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  
  .btn-dark {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
  
  .btn-dark:hover {
    color: #fff;
    background-color: #404040;
    border-color: #3c3c3c; }
  
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #404040;
    border-color: #3c3c3c;
    box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5); }
  
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #383838; }
  
  .btn-check:checked + .btn-dark:focus,
  .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5); }
  
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
  
  .btn-outline-primary {
    color: #b5151c;
    border-color: #b5151c; }
  
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(115, 103, 240, 0.5); }
  
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .btn-check:checked + .btn-outline-primary:focus,
  .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(115, 103, 240, 0.5); }
  
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #b5151c;
    background-color: transparent; }
  
  .btn-outline-secondary {
    color: #82868b;
    border-color: #82868b; }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #82868b;
    border-color: #82868b; }
  
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(130, 134, 139, 0.5); }
  
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #82868b;
    border-color: #82868b; }
  
  .btn-check:checked + .btn-outline-secondary:focus,
  .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(130, 134, 139, 0.5); }
  
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #82868b;
    background-color: transparent; }
  
  .btn-outline-success {
    color: #28c76f;
    border-color: #28c76f; }
  
  .btn-outline-success:hover {
    color: #22292f;
    background-color: #28c76f;
    border-color: #28c76f; }
  
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.5); }
  
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #22292f;
    background-color: #28c76f;
    border-color: #28c76f; }
  
  .btn-check:checked + .btn-outline-success:focus,
  .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.5); }
  
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #28c76f;
    background-color: transparent; }
  
  .btn-outline-info {
    color: #00cfe8;
    border-color: #00cfe8; }
  
  .btn-outline-info:hover {
    color: #22292f;
    background-color: #00cfe8;
    border-color: #00cfe8; }
  
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 207, 232, 0.5); }
  
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #22292f;
    background-color: #00cfe8;
    border-color: #00cfe8; }
  
  .btn-check:checked + .btn-outline-info:focus,
  .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 207, 232, 0.5); }
  
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #00cfe8;
    background-color: transparent; }
  
  .btn-outline-warning {
    color: #ff9f43;
    border-color: #ff9f43; }
  
  .btn-outline-warning:hover {
    color: #22292f;
    background-color: #ff9f43;
    border-color: #ff9f43; }
  
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 159, 67, 0.5); }
  
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #22292f;
    background-color: #ff9f43;
    border-color: #ff9f43; }
  
  .btn-check:checked + .btn-outline-warning:focus,
  .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 159, 67, 0.5); }
  
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ff9f43;
    background-color: transparent; }
  
  .btn-outline-danger {
    color: #ea5455;
    border-color: #ea5455; }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
  
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.5); }
  
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #ea5455;
    border-color: #ea5455; }
  
  .btn-check:checked + .btn-outline-danger:focus,
  .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.5); }
  
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #ea5455;
    background-color: transparent; }
  
  .btn-outline-light {
    color: #f6f6f6;
    border-color: #f6f6f6; }
  
  .btn-outline-light:hover {
    color: #22292f;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(246, 246, 246, 0.5); }
  
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #22292f;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  
  .btn-check:checked + .btn-outline-light:focus,
  .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(246, 246, 246, 0.5); }
  
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f6f6f6;
    background-color: transparent; }
  
  .btn-outline-dark {
    color: #4b4b4b;
    border-color: #4b4b4b; }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
  
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(75, 75, 75, 0.5); }
  
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
  
  .btn-check:checked + .btn-outline-dark:focus,
  .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(75, 75, 75, 0.5); }
  
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #4b4b4b;
    background-color: transparent; }
  
  .btn-link {
    font-weight: 400;
    color: #b5151c;
    text-decoration: none; }
  
  .btn-link:hover {
    color: #b5151a;
    text-decoration: none; }
  
  .btn-link:focus {
    text-decoration: none; }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #b8c2cc; }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.8rem 2rem;
    font-size: 1.25rem;
    border-radius: 0.358rem; }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.486rem 1rem;
    font-size: 0.9rem;
    border-radius: 0.358rem; }
  
  .fade {
    transition: opacity 0.15s linear; }
  
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  
  .fade:not(.show) {
    opacity: 0; }
  
  .collapse:not(.show) {
    display: none; }
  
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      transition: none; } }
  
  .dropup,
  .dropend,
  .dropdown,
  .dropstart {
    position: relative; }
  
  .dropdown-toggle {
    white-space: nowrap; }
  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  
  .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #6e6b7b;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(34, 41, 47, 0.05);
    border-radius: 0.357rem; }
  
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0; }
  
  .dropdown-menu-start {
    --bs-position: start; }
  
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }
  
  .dropdown-menu-end {
    --bs-position: end; }
  
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }
  
  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .dropdown-menu-sm-end {
      --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .dropdown-menu-md-end {
      --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .dropdown-menu-lg-end {
      --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .dropdown-menu-xl-end {
      --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  
  @media (min-width: 1440px) {
    .dropdown-menu-xxl-start {
      --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .dropdown-menu-xxl-end {
      --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  
  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0; }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0; }
  
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropend .dropdown-toggle::after {
    vertical-align: 0; }
  
  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0; }
  
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  
  .dropstart .dropdown-toggle::after {
    display: none; }
  
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropstart .dropdown-toggle::before {
    vertical-align: 0; }
  
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(34, 41, 47, 0.08); }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.65rem 1.28rem;
    clear: both;
    font-weight: 400;
    color: #6e6b7b;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  
  .dropdown-item:hover, .dropdown-item:focus {
    color: #b5151c;
    background-color: rgba(115, 103, 240, 0.12); }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #b5151c; }
  
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #b9b9c3;
    pointer-events: none;
    background-color: transparent; }
  
  .dropdown-menu.show {
    display: block; }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.28rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #5e5873;
    white-space: nowrap; }
  
  .dropdown-item-text {
    display: block;
    padding: 0.65rem 1.28rem;
    color: #6e6b7b; }
  
  .dropdown-menu-dark {
    color: #dae1e7;
    background-color: #1e1e1e;
    border-color: rgba(34, 41, 47, 0.05); }
  
  .dropdown-menu-dark .dropdown-item {
    color: #dae1e7; }
  
  .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15); }
  
  .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #b5151c; }
  
  .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd; }
  
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(34, 41, 47, 0.08); }
  
  .dropdown-menu-dark .dropdown-item-text {
    color: #dae1e7; }
  
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
  
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }
  
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  
  .btn-toolbar .input-group {
    width: auto; }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  
  .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem; }
  
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  
  .nav-link {
    display: block;
    padding: 0.358rem 0.5rem;
    color: #b5151c;
    transition: none; }
  
  .nav-link:hover, .nav-link:focus {
    color: #b5151a; }
  
  .nav-link.disabled {
    color: #b9b9c3;
    pointer-events: none;
    cursor: default; }
  
  .nav-tabs {
    border-bottom: 0 solid #dae1e7; }
  
  .nav-tabs .nav-link {
    margin-bottom: 0;
    background: none;
    border: 0 solid transparent;
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem; }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #ededed #ededed #dae1e7;
    isolation: isolate; }
  
  .nav-tabs .nav-link.disabled {
    color: #b9b9c3;
    background-color: transparent;
    border-color: transparent; }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #b5151c;
    background-color: transparent;
    border-color: #dae1e7 #dae1e7 transparent; }
  
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.358rem; }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #b5151c; }
  
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center; }
  
  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }
  
  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 100%; }
  
  .tab-content > .tab-pane {
    display: none; }
  
  .tab-content > .active {
    display: block; }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-xs, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }
  
  .navbar-brand {
    padding-top: -0.367rem;
    padding-bottom: -0.367rem;
    margin-right: 1rem;
    font-size: calc(1.325rem + 0.9vw);
    white-space: nowrap; }
  
  @media (min-width: 1200px) {
    .navbar-brand {
      font-size: 2rem; } }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  
  .navbar-nav .dropdown-menu {
    position: static; }
  
  .navbar-text {
    padding-top: 0.358rem;
    padding-bottom: 0.358rem; }
  
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: calc(1.325rem + 0.9vw);
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.358rem;
    transition: box-shadow 0.15s ease-in-out; }
  
  @media (min-width: 1200px) {
    .navbar-toggler {
      font-size: 2rem; } }
  
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  
  .navbar-toggler:hover {
    text-decoration: none; }
  
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; }
  
  .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto; }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  
  @media (min-width: 1440px) {
    .navbar-expand-xxl {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  
  .navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  
  .navbar-expand .navbar-nav {
    flex-direction: row; }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  
  .navbar-expand .navbar-toggler {
    display: none; }
  
  .navbar-expand .offcanvas-header {
    display: none; }
  
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }
  
  .navbar-light .navbar-brand {
    color: rgba(34, 41, 47, 0.9); }
  
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(34, 41, 47, 0.9); }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(34, 41, 47, 0.55); }
  
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(34, 41, 47, 0.7); }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(34, 41, 47, 0.3); }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(34, 41, 47, 0.9); }
  
  .navbar-light .navbar-toggler {
    color: rgba(34, 41, 47, 0.55);
    border-color: rgba(34, 41, 47, 0.1); }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2834, 41, 47, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  
  .navbar-light .navbar-text {
    color: rgba(34, 41, 47, 0.55); }
  
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(34, 41, 47, 0.9); }
  
  .navbar-dark .navbar-brand {
    color: #fff; }
  
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.55); }
  
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1); }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.55); }
  
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(34, 41, 47, 0.125);
    border-radius: 0.428rem; }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
  
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 0.428rem;
    border-top-right-radius: 0.428rem; }
  
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 0.428rem;
    border-bottom-left-radius: 0.428rem; }
  
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }
  
  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem; }
  
  .card-title {
    margin-bottom: 1.5rem; }
  
  .card-subtitle {
    margin-top: -0.75rem;
    margin-bottom: 0; }
  
  .card-text:last-child {
    margin-bottom: 0; }
  
  .card-link + .card-link {
    margin-left: 1.5rem; }
  
  .card-header {
    padding: 1.5rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0 solid rgba(34, 41, 47, 0.125); }
  
  .card-header:first-child {
    border-radius: 0.428rem 0.428rem 0 0; }
  
  .card-footer {
    padding: 1.5rem 1.5rem;
    background-color: transparent;
    border-top: 0 solid rgba(34, 41, 47, 0.125); }
  
  .card-footer:last-child {
    border-radius: 0 0 0.428rem 0.428rem; }
  
  .card-header-tabs {
    margin-right: -0.75rem;
    margin-bottom: -1.5rem;
    margin-left: -0.75rem;
    border-bottom: 0; }
  
  .card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff; }
  
  .card-header-pills {
    margin-right: -0.75rem;
    margin-left: -0.75rem; }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: 0.428rem; }
  
  .card-img,
  .card-img-top,
  .card-img-bottom {
    width: 100%; }
  
  .card-img,
  .card-img-top {
    border-top-left-radius: 0.428rem;
    border-top-right-radius: 0.428rem; }
  
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: 0.428rem;
    border-bottom-left-radius: 0.428rem; }
  
  .card-group > .card {
    margin-bottom: 1rem; }
  
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0; }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0; }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0; }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0; }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0; } }
  
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem;
    font-size: 1rem;
    color: #6e6b7b;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s, border-radius 0.15s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  
  .accordion-button:not(.collapsed) {
    color: #6e6b7b;
    background-color: transparent;
    box-shadow: inset 0 0 0 rgba(34, 41, 47, 0.125); }
  
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3e%3cpolyline points='18 15 12 9 6 15'%3e%3c/polyline%3e%3c/svg%3e");
    transform: rotate(-180deg); }
  
  .accordion-button::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3e%3cpolyline points='18 15 12 9 6 15'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1rem;
    transition: transform 0.2s ease-in-out; }
  
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      transition: none; } }
  
  .accordion-button:hover {
    z-index: 2; }
  
  .accordion-button:focus {
    z-index: 3;
    border-color: #b5151c;
    outline: 0;
    box-shadow: none; }
  
  .accordion-header {
    margin-bottom: 0; }
  
  .accordion-item {
    background-color: #fff;
    border: 0 solid rgba(34, 41, 47, 0.125); }
  
  .accordion-item:first-of-type {
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem; }
  
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem; }
  
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem; }
  
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem; }
  
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem; }
  
  .accordion-body {
    padding: 1rem 1rem; }
  
  .accordion-flush .accordion-collapse {
    border-width: 0; }
  
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0; }
  
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }
  
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.3rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0; }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.6rem; }
  
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.6rem;
    color: #6e6b7b;
    content: var(--bs-breadcrumb-divider, "/"); }
  
  .breadcrumb-item.active {
    color: #6e6b7b; }
  
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none; }
  
  .page-link {
    position: relative;
    display: block;
    color: #6e6b7b;
    background-color: #f3f2f7;
    border: 0 solid #dae1e7;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  
  .page-link:hover {
    z-index: 2;
    color: #b5151c;
    background-color: #f3f2f7;
    border-color: #dae1e7; }
  
  .page-link:focus {
    z-index: 3;
    color: inherit;
    background-color: #f3f2f7;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(115, 103, 240, 0.25); }
  
  .page-item:not(:first-child) .page-link {
    margin-left: 0; }
  
  .page-item.active .page-link {
    z-index: 3;
    color: #b5151c;
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .page-item.disabled .page-link {
    color: #b9b9c3;
    pointer-events: none;
    background-color: #f3f2f7;
    border-color: #dae1e7; }
  
  .page-link {
    padding: 0.5rem 0.85rem; }
  
  .page-item:first-child .page-link {
    border-top-left-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem; }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem; }
  
  .pagination-lg .page-link {
    padding: 0.5575rem 1rem;
    font-size: calc(1.325rem + 0.9vw); }
  
  @media (min-width: 1200px) {
    .pagination-lg .page-link {
      font-size: 2rem; } }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem; }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem; }
  
  .pagination-sm .page-link {
    padding: 0.443rem 0.745rem;
    font-size: 1rem; }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  
  .badge {
    display: inline-block;
    padding: 0.3rem 0.5rem;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.358rem; }
  
  .badge:empty {
    display: none; }
  
  .btn .badge {
    position: relative;
    top: -1px; }
  
  .alert {
    position: relative;
    padding: 0.71rem 1rem;
    margin-bottom: 1rem;
    border: 0 solid transparent;
    border-radius: 0.358rem; }
  
  .alert-heading {
    color: inherit; }
  
  .alert-link {
    font-weight: 700; }
  
  .alert-dismissible {
    padding-right: 3rem; }
  
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.8875rem 1rem; }
  
  .alert-primary {
    color: #453e90;
    background-color: #e3e1fc;
    border-color: #d5d1fb; }
  
  .alert-primary .alert-link {
    color: #373273; }
  
  .alert-secondary {
    color: #4e5053;
    background-color: #e6e7e8;
    border-color: #dadbdc; }
  
  .alert-secondary .alert-link {
    color: #3e4042; }
  
  .alert-success {
    color: #187743;
    background-color: #d4f4e2;
    border-color: #bfeed4; }
  
  .alert-success .alert-link {
    color: #135f36; }
  
  .alert-info {
    color: #007c8b;
    background-color: #ccf5fa;
    border-color: #b3f1f8; }
  
  .alert-info .alert-link {
    color: #00636f; }
  
  .alert-warning {
    color: #995f28;
    background-color: #ffecd9;
    border-color: #ffe2c7; }
  
  .alert-warning .alert-link {
    color: #7a4c20; }
  
  .alert-danger {
    color: #8c3233;
    background-color: #fbdddd;
    border-color: #f9cccc; }
  
  .alert-danger .alert-link {
    color: #702829; }
  
  .alert-light {
    color: #777b7f;
    background-color: #fdfdfd;
    border-color: #fcfcfc; }
  
  .alert-light .alert-link {
    color: #5f6266; }
  
  .alert-dark {
    color: #2d2d2d;
    background-color: #dbdbdb;
    border-color: #c9c9c9; }
  
  .alert-dark .alert-link {
    color: #242424; }
  
  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 0.857rem; } }
  
  .progress {
    display: flex;
    height: 0.857rem;
    overflow: hidden;
    font-size: 0.857rem;
    background-color: rgba(115, 103, 240, 0.12);
    border-radius: 5rem; }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #b5151c;
    transition: width 0.6s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 0.857rem 0.857rem; }
  
  .progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes; }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }
  
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.357rem; }
  
  .list-group-numbered {
    list-style-type: none;
    counter-reset: section; }
  
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }
  
  .list-group-item-action {
    width: 100%;
    color: #6e6b7b;
    text-align: inherit; }
  
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #6e6b7b;
    text-decoration: none;
    background-color: #f8f8f8; }
  
  .list-group-item-action:active {
    color: #fff;
    background-color: #b5151c; }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    color: #6e6b7b;
    background-color: #fff;
    border: 1px solid rgba(34, 41, 47, 0.125); }
  
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  
  .list-group-item.disabled, .list-group-item:disabled {
    color: #b9b9c3;
    pointer-events: none;
    background-color: #fff; }
  
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #b5151c;
    border-color: #b5151c; }
  
  .list-group-item + .list-group-item {
    border-top-width: 0; }
  
  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px; }
  
  .list-group-horizontal {
    flex-direction: row; }
  
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.357rem;
    border-top-right-radius: 0; }
  
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.357rem;
    border-bottom-left-radius: 0; }
  
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; }
  
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.357rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.357rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.357rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.357rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.357rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.357rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.357rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.357rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  
  @media (min-width: 1440px) {
    .list-group-horizontal-xxl {
      flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.357rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.357rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  
  .list-group-flush {
    border-radius: 0; }
  
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
  
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0; }
  
  .list-group-item-primary {
    color: #453e90;
    background-color: #e3e1fc; }
  
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #453e90;
    background-color: #cccbe3; }
  
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #453e90;
    border-color: #453e90; }
  
  .list-group-item-secondary {
    color: #4e5053;
    background-color: #e6e7e8; }
  
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4e5053;
    background-color: #cfd0d1; }
  
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #4e5053;
    border-color: #4e5053; }
  
  .list-group-item-success {
    color: #187743;
    background-color: #d4f4e2; }
  
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #187743;
    background-color: #bfdccb; }
  
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #187743;
    border-color: #187743; }
  
  .list-group-item-info {
    color: #007c8b;
    background-color: #ccf5fa; }
  
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #007c8b;
    background-color: #b8dde1; }
  
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #007c8b;
    border-color: #007c8b; }
  
  .list-group-item-warning {
    color: #995f28;
    background-color: #ffecd9; }
  
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #995f28;
    background-color: #e6d4c3; }
  
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #995f28;
    border-color: #995f28; }
  
  .list-group-item-danger {
    color: #8c3233;
    background-color: #fbdddd; }
  
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #8c3233;
    background-color: #e2c7c7; }
  
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8c3233;
    border-color: #8c3233; }
  
  .list-group-item-light {
    color: #777b7f;
    background-color: #fdfdfd; }
  
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #777b7f;
    background-color: #e4e4e4; }
  
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #777b7f;
    border-color: #777b7f; }
  
  .list-group-item-dark {
    color: #2d2d2d;
    background-color: #dbdbdb; }
  
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #2d2d2d;
    background-color: #c5c5c5; }
  
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #2d2d2d; }
  
  .btn-close {
    box-sizing: content-box;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.25em 0.25em;
    color: currentColor;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
    border: 0;
    border-radius: 0.357rem;
    opacity: 0.4; }
  
  .btn-close:hover {
    color: currentColor;
    text-decoration: none;
    opacity: 0.65; }
  
  .btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1; }
  
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }
  
  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%); }
  
  .toast {
    width: 380px;
    max-width: 100%;
    font-size: 0.857rem;
    color: #6e6b7b;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 20px 0 rgba(34, 41, 47, 0.08);
    border-radius: 0.286rem; }
  
  .toast.showing {
    opacity: 0; }
  
  .toast:not(.show) {
    display: none; }
  
  .toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none; }
  
  .toast-container > :not(:last-child) {
    margin-bottom: 1rem; }
  
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.14rem;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 0 solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0.286rem;
    border-top-right-radius: 0.286rem; }
  
  .toast-header .btn-close {
    margin-right: -0.57rem;
    margin-left: 1.14rem; }
  
  .toast-body {
    padding: 1.14rem;
    word-wrap: break-word; }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0; }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
  
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
  
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none; } }
  
  .modal.show .modal-dialog {
    transform: none; }
  
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }
  
  .modal-dialog-scrollable {
    height: calc(100% - 1rem); }
  
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(34, 41, 47, 0.2);
    border-radius: 0.357rem;
    outline: 0; }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #22292f; }
  
  .modal-backdrop.fade {
    opacity: 0; }
  
  .modal-backdrop.show {
    opacity: 0.5; }
  
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.4rem;
    border-bottom: 0 solid #ebe9f1;
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem; }
  
  .modal-header .btn-close {
    padding: 0.4rem 0.7rem;
    margin: -0.4rem -0.7rem -0.4rem auto; }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.45; }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0.8rem 1.4rem; }
  
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 1.15rem;
    border-top: 1px solid rgba(34, 41, 47, 0.05);
    border-bottom-right-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem; }
  
  .modal-footer > * {
    margin: 0.25rem; }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .modal-dialog-scrollable {
      height: calc(100% - 3.5rem); }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
    .modal-sm {
      max-width: 400px; } }
  
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px; } }
  
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 94%; } }
  
  .modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  
  @media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }
  
  @media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }
  
  @media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }
  
  @media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }
  
  @media (max-width: 1439.98px) {
    .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }
  
  .tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.45;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.857rem;
    word-wrap: break-word;
    opacity: 0; }
  
  .tooltip.show {
    opacity: 1; }
  
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
  
  .tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid; }
  
  .bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
    padding: 0.4rem 0; }
  
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
  
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #323232; }
  
  .bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
    padding: 0 0.4rem; }
  
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
  
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #323232; }
  
  .bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
    padding: 0.4rem 0; }
  
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
  
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #323232; }
  
  .bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
    padding: 0 0.4rem; }
  
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
  
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #323232; }
  
  .tooltip-inner {
    max-width: 220px;
    padding: 0.4rem 0.775rem;
    color: #fff;
    text-align: center;
    background-color: #323232;
    border-radius: 0.358rem; }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.45;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 1rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid #ebe9f1;
    border-radius: 0.358rem; }
  
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
  
  .popover .popover-arrow::before, .popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid; }
  
  .bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    bottom: -0.5rem; }
  
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ebe9f1; }
  
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }
  
  .bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
    left: -0.5rem;
    width: 0.5rem;
    height: 1rem; }
  
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ebe9f1; }
  
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }
  
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
    top: -0.5rem; }
  
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ebe9f1; }
  
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }
  
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 0 solid #b5151c; }
  
  .bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
    right: -0.5rem;
    width: 0.5rem;
    height: 1rem; }
  
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ebe9f1; }
  
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }
  
  .popover-header {
    padding: 0.65rem 1.21rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #fff;
    background-color: #b5151c;
    border-bottom: 0 solid #ebe9f1;
    border-top-left-radius: 0.358rem;
    border-top-right-radius: 0.358rem; }
  
  .popover-header:empty {
    display: none; }
  
  .popover-body {
    padding: 1rem 1rem;
    color: #6e6b7b; }
  
  .carousel {
    position: relative; }
  
  .carousel.pointer-event {
    touch-action: pan-y; }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
  
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out; }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block; }
  
  /* rtl:begin:ignore */
  .carousel-item-next:not(.carousel-item-start),
  .active.carousel-item-end {
    transform: translateX(100%); }
  
  .carousel-item-prev:not(.carousel-item-end),
  .active.carousel-item-start {
    transform: translateX(-100%); }
  
  /* rtl:end:ignore */
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none; }
  
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1; }
  
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s; }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }
  
  .carousel-control-prev {
    left: 0; }
  
  .carousel-control-next {
    right: 0; }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 1.43rem;
    height: 1.43rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%; }
  
  /* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
  .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"%3e%3cpolyline points="15 18 9 12 15 6"%3e%3c/polyline%3e%3c/svg%3e'); }
  
  .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"%3e%3cpolyline points="9 18 15 12 9 6"%3e%3c/polyline%3e%3c/svg%3e'); }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none; }
  
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
      transition: none; } }
  
  .carousel-indicators .active {
    opacity: 1; }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center; }
  
  .carousel-dark .carousel-control-prev-icon,
  .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100); }
  
  .carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #22292f; }
  
  .carousel-dark .carousel-caption {
    color: #22292f; }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg); } }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border; }
  
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }
  
  @keyframes spinner-grow {
    0% {
      transform: scale(0); }
    50% {
      opacity: 1;
      transform: none; } }
  
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: 0.75s linear infinite spinner-grow; }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  
  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
      animation-duration: 1.5s; } }
  
  .offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
  
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #22292f; }
  
  .offcanvas-backdrop.fade {
    opacity: 0; }
  
  .offcanvas-backdrop.show {
    opacity: 0.5; }
  
  .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.4rem; }
  
  .offcanvas-header .btn-close {
    padding: 0.4rem 0.7rem;
    margin-top: -0.4rem;
    margin-right: -0.7rem;
    margin-bottom: -0.4rem; }
  
  .offcanvas-title {
    margin-bottom: 0;
    line-height: 1.45; }
  
  .offcanvas-body {
    flex-grow: 1;
    padding: 0.8rem 1.4rem;
    overflow-y: auto; }
  
  .offcanvas-start {
    top: 0;
    left: 0;
    width: 320px;
    border-right: 0 solid rgba(34, 41, 47, 0.2);
    transform: translateX(-100%); }
  
  .offcanvas-end {
    top: 0;
    right: 0;
    width: 320px;
    border-left: 0 solid rgba(34, 41, 47, 0.2);
    transform: translateX(100%); }
  
  .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 0 solid rgba(34, 41, 47, 0.2);
    transform: translateY(-100%); }
  
  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 0 solid rgba(34, 41, 47, 0.2);
    transform: translateY(100%); }
  
  .offcanvas.show {
    transform: none; }
  
  .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.5; }
  
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }
  
  .placeholder-xs {
    min-height: .6em; }
  
  .placeholder-sm {
    min-height: .8em; }
  
  .placeholder-lg {
    min-height: 1.2em; }
  
  .placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite; }
  
  @keyframes placeholder-glow {
    50% {
      opacity: 0.2; } }
  
  .placeholder-wave {
    mask-image: linear-gradient(130deg, #22292f 55%, rgba(0, 0, 0, 0.8) 75%, #22292f 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite; }
  
  @keyframes placeholder-wave {
    100% {
      mask-position: -200% 0%; } }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  
  .link-primary {
    color: #b5151c; }
  
  .link-primary:hover, .link-primary:focus {
    color: #b5151a; }
  
  .link-secondary {
    color: #82868b; }
  
  .link-secondary:hover, .link-secondary:focus {
    color: #7c7f84; }
  
  .link-success {
    color: #28c76f; }
  
  .link-success:hover, .link-success:focus {
    color: #33ca76; }
  
  .link-info {
    color: #00cfe8; }
  
  .link-info:hover, .link-info:focus {
    color: #0dd1e9; }
  
  .link-warning {
    color: #ff9f43; }
  
  .link-warning:hover, .link-warning:focus {
    color: #ffa44c; }
  
  .link-danger {
    color: #ea5455; }
  
  .link-danger:hover, .link-danger:focus {
    color: #de5051; }
  
  .link-light {
    color: #f6f6f6; }
  
  .link-light:hover, .link-light:focus {
    color: #f6f6f6; }
  
  .link-dark {
    color: #4b4b4b; }
  
  .link-dark:hover, .link-dark:focus {
    color: #474747; }
  
  .ratio {
    position: relative;
    width: 100%; }
  
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  
  .ratio-1x1 {
    --bs-aspect-ratio: 100%; }
  
  .ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%); }
  
  .ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%); }
  
  .ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%); }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 768px) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 1440px) {
    .sticky-xxl-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  .hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch; }
  
  .vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch; }
  
  .visually-hidden,
  .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important; }
  
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""; }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  
  .vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 1; }
  
  .align-baseline {
    vertical-align: baseline !important; }
  
  .align-top {
    vertical-align: top !important; }
  
  .align-middle {
    vertical-align: middle !important; }
  
  .align-bottom {
    vertical-align: bottom !important; }
  
  .align-text-bottom {
    vertical-align: text-bottom !important; }
  
  .align-text-top {
    vertical-align: text-top !important; }
  
  .float-start {
    float: left !important; }
  
  .float-end {
    float: right !important; }
  
  .float-none {
    float: none !important; }
  
  .opacity-0 {
    opacity: 0 !important; }
  
  .opacity-25 {
    opacity: 0.25 !important; }
  
  .opacity-50 {
    opacity: 0.5 !important; }
  
  .opacity-75 {
    opacity: 0.75 !important; }
  
  .opacity-100 {
    opacity: 1 !important; }
  
  .overflow-auto {
    overflow: auto !important; }
  
  .overflow-hidden {
    overflow: hidden !important; }
  
  .overflow-visible {
    overflow: visible !important; }
  
  .overflow-scroll {
    overflow: scroll !important; }
  
  .d-inline {
    display: inline !important; }
  
  .d-inline-block {
    display: inline-block !important; }
  
  .d-block {
    display: block !important; }
  
  .d-grid {
    display: grid !important; }
  
  .d-table {
    display: table !important; }
  
  .d-table-row {
    display: table-row !important; }
  
  .d-table-cell {
    display: table-cell !important; }
  
  .d-flex {
    display: flex !important; }
  
  .d-inline-flex {
    display: inline-flex !important; }
  
  .d-none {
    display: none !important; }
  
  .shadow {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important; }
  
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(34, 41, 47, 0.075) !important; }
  
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(34, 41, 47, 0.175) !important; }
  
  .shadow-none {
    box-shadow: none !important; }
  
  .position-static {
    position: static !important; }
  
  .position-relative {
    position: relative !important; }
  
  .position-absolute {
    position: absolute !important; }
  
  .position-fixed {
    position: fixed !important; }
  
  .position-sticky {
    position: sticky !important; }
  
  .top-0 {
    top: 0 !important; }
  
  .top-50 {
    top: 50% !important; }
  
  .top-100 {
    top: 100% !important; }
  
  .bottom-0 {
    bottom: 0 !important; }
  
  .bottom-50 {
    bottom: 50% !important; }
  
  .bottom-100 {
    bottom: 100% !important; }
  
  .start-0 {
    left: 0 !important; }
  
  .start-50 {
    left: 50% !important; }
  
  .start-100 {
    left: 100% !important; }
  
  .end-0 {
    right: 0 !important; }
  
  .end-50 {
    right: 50% !important; }
  
  .end-100 {
    right: 100% !important; }
  
  .translate-middle {
    transform: translate(-50%, -50%) !important; }
  
  .translate-middle-x {
    transform: translateX(-50%) !important; }
  
  .translate-middle-y {
    transform: translateY(-50%) !important; }
  
  .border {
    border: 1px solid #ebe9f1 !important; }
  
  .border-0 {
    border: 0 !important; }
  
  .border-top {
    border-top: 1px solid #ebe9f1 !important; }
  
  .border-top-0 {
    border-top: 0 !important; }
  
  .border-end {
    border-right: 1px solid #ebe9f1 !important; }
  
  .border-end-0 {
    border-right: 0 !important; }
  
  .border-bottom {
    border-bottom: 1px solid #ebe9f1 !important; }
  
  .border-bottom-0 {
    border-bottom: 0 !important; }
  
  .border-start {
    border-left: 1px solid #ebe9f1 !important; }
  
  .border-start-0 {
    border-left: 0 !important; }
  
  .border-primary {
    border-color: #b5151c !important; }
  
  .border-secondary {
    border-color: #82868b !important; }
  
  .border-success {
    border-color: #28c76f !important; }
  
  .border-info {
    border-color: #00cfe8 !important; }
  
  .border-warning {
    border-color: #ff9f43 !important; }
  
  .border-danger {
    border-color: #ea5455 !important; }
  
  .border-light {
    border-color: #f6f6f6 !important; }
  
  .border-dark {
    border-color: #4b4b4b !important; }
  
  .border-white {
    border-color: #fff !important; }
  
  .border-1 {
    border-width: 1px !important; }
  
  .border-2 {
    border-width: 2px !important; }
  
  .border-3 {
    border-width: 3px !important; }
  
  .border-4 {
    border-width: 4px !important; }
  
  .border-5 {
    border-width: 5px !important; }
  
  .w-25 {
    width: 25% !important; }
  
  .w-50 {
    width: 50% !important; }
  
  .w-75 {
    width: 75% !important; }
  
  .w-100 {
    width: 100% !important; }
  
  .w-auto {
    width: auto !important; }
  
  .mw-100 {
    max-width: 100% !important; }
  
  .vw-100 {
    width: 100vw !important; }
  
  .min-vw-100 {
    min-width: 100vw !important; }
  
  .h-25 {
    height: 25% !important; }
  
  .h-50 {
    height: 50% !important; }
  
  .h-75 {
    height: 75% !important; }
  
  .h-100 {
    height: 100% !important; }
  
  .h-auto {
    height: auto !important; }
  
  .mh-100 {
    max-height: 100% !important; }
  
  .vh-100 {
    height: 100vh !important; }
  
  .min-vh-100 {
    min-height: 100vh !important; }
  
  .flex-fill {
    flex: 1 1 auto !important; }
  
  .flex-row {
    flex-direction: row !important; }
  
  .flex-column {
    flex-direction: column !important; }
  
  .flex-row-reverse {
    flex-direction: row-reverse !important; }
  
  .flex-column-reverse {
    flex-direction: column-reverse !important; }
  
  .flex-grow-0 {
    flex-grow: 0 !important; }
  
  .flex-grow-1 {
    flex-grow: 1 !important; }
  
  .flex-shrink-0 {
    flex-shrink: 0 !important; }
  
  .flex-shrink-1 {
    flex-shrink: 1 !important; }
  
  .flex-wrap {
    flex-wrap: wrap !important; }
  
  .flex-nowrap {
    flex-wrap: nowrap !important; }
  
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  
  .gap-0 {
    gap: 0 !important; }
  
  .gap-25 {
    gap: 0.25rem !important; }
  
  .gap-50 {
    gap: 0.5rem !important; }
  
  .gap-75 {
    gap: 0.75rem !important; }
  
  .gap-1 {
    gap: 1rem !important; }
  
  .gap-2 {
    gap: 1.5rem !important; }
  
  .gap-3 {
    gap: 3rem !important; }
  
  .gap-4 {
    gap: 3.5rem !important; }
  
  .gap-5 {
    gap: 4rem !important; }
  
  .justify-content-start {
    justify-content: flex-start !important; }
  
  .justify-content-end {
    justify-content: flex-end !important; }
  
  .justify-content-center {
    justify-content: center !important; }
  
  .justify-content-between {
    justify-content: space-between !important; }
  
  .justify-content-around {
    justify-content: space-around !important; }
  
  .justify-content-evenly {
    justify-content: space-evenly !important; }
  
  .align-items-start {
    align-items: flex-start !important; }
  
  .align-items-end {
    align-items: flex-end !important; }
  
  .align-items-center {
    align-items: center !important; }
  
  .align-items-baseline {
    align-items: baseline !important; }
  
  .align-items-stretch {
    align-items: stretch !important; }
  
  .align-content-start {
    align-content: flex-start !important; }
  
  .align-content-end {
    align-content: flex-end !important; }
  
  .align-content-center {
    align-content: center !important; }
  
  .align-content-between {
    align-content: space-between !important; }
  
  .align-content-around {
    align-content: space-around !important; }
  
  .align-content-stretch {
    align-content: stretch !important; }
  
  .align-self-auto {
    align-self: auto !important; }
  
  .align-self-start {
    align-self: flex-start !important; }
  
  .align-self-end {
    align-self: flex-end !important; }
  
  .align-self-center {
    align-self: center !important; }
  
  .align-self-baseline {
    align-self: baseline !important; }
  
  .align-self-stretch {
    align-self: stretch !important; }
  
  .order-first {
    order: -1 !important; }
  
  .order-0 {
    order: 0 !important; }
  
  .order-1 {
    order: 1 !important; }
  
  .order-2 {
    order: 2 !important; }
  
  .order-3 {
    order: 3 !important; }
  
  .order-4 {
    order: 4 !important; }
  
  .order-5 {
    order: 5 !important; }
  
  .order-last {
    order: 6 !important; }
  
  .m-0 {
    margin: 0 !important; }
  
  .m-25 {
    margin: 0.25rem !important; }
  
  .m-50 {
    margin: 0.5rem !important; }
  
  .m-75 {
    margin: 0.75rem !important; }
  
  .m-1 {
    margin: 1rem !important; }
  
  .m-2 {
    margin: 1.5rem !important; }
  
  .m-3 {
    margin: 3rem !important; }
  
  .m-4 {
    margin: 3.5rem !important; }
  
  .m-5 {
    margin: 4rem !important; }
  
  .m-auto {
    margin: auto !important; }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  
  .mx-25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  
  .mx-50 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  
  .mx-75 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  
  .mx-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  
  .mx-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  
  .mx-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  
  .mx-4 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  
  .mx-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  
  .my-25 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  
  .my-50 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  
  .my-75 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  
  .my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  
  .my-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  
  .my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  
  .my-4 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  
  .my-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  
  .mt-0 {
    margin-top: 0 !important; }
  
  .mt-25 {
    margin-top: 0.25rem !important; }
  
  .mt-50 {
    margin-top: 0.5rem !important; }
  
  .mt-75 {
    margin-top: 0.75rem !important; }
  
  .mt-1 {
    margin-top: 1rem !important; }
  
  .mt-2 {
    margin-top: 1.5rem !important; }
  
  .mt-3 {
    margin-top: 3rem !important; }
  
  .mt-4 {
    margin-top: 3.5rem !important; }
  
  .mt-5 {
    margin-top: 4rem !important; }
  
  .mt-auto {
    margin-top: auto !important; }
  
  .me-0 {
    margin-right: 0 !important; }
  
  .me-25 {
    margin-right: 0.25rem !important; }
  
  .me-50 {
    margin-right: 0.5rem !important; }
  
  .me-75 {
    margin-right: 0.75rem !important; }
  
  .me-1 {
    margin-right: 1rem !important; }
  
  .me-2 {
    margin-right: 1.5rem !important; }
  
  .me-3 {
    margin-right: 3rem !important; }
  
  .me-4 {
    margin-right: 3.5rem !important; }
  
  .me-5 {
    margin-right: 4rem !important; }
  
  .me-auto {
    margin-right: auto !important; }
  
  .mb-0 {
    margin-bottom: 0 !important; }
  
  .mb-25 {
    margin-bottom: 0.25rem !important; }
  
  .mb-50 {
    margin-bottom: 0.5rem !important; }
  
  .mb-75 {
    margin-bottom: 0.75rem !important; }
  
  .mb-1 {
    margin-bottom: 1rem !important; }
  
  .mb-2 {
    margin-bottom: 1.5rem !important; }
  
  .mb-3 {
    margin-bottom: 3rem !important; }
  
  .mb-4 {
    margin-bottom: 3.5rem !important; }
  
  .mb-5 {
    margin-bottom: 4rem !important; }
  
  .mb-auto {
    margin-bottom: auto !important; }
  
  .ms-0 {
    margin-left: 0 !important; }
  
  .ms-25 {
    margin-left: 0.25rem !important; }
  
  .ms-50 {
    margin-left: 0.5rem !important; }
  
  .ms-75 {
    margin-left: 0.75rem !important; }
  
  .ms-1 {
    margin-left: 1rem !important; }
  
  .ms-2 {
    margin-left: 1.5rem !important; }
  
  .ms-3 {
    margin-left: 3rem !important; }
  
  .ms-4 {
    margin-left: 3.5rem !important; }
  
  .ms-5 {
    margin-left: 4rem !important; }
  
  .ms-auto {
    margin-left: auto !important; }
  
  .p-0 {
    padding: 0 !important; }
  
  .p-25 {
    padding: 0.25rem !important; }
  
  .p-50 {
    padding: 0.5rem !important; }
  
  .p-75 {
    padding: 0.75rem !important; }
  
  .p-1 {
    padding: 1rem !important; }
  
  .p-2 {
    padding: 1.5rem !important; }
  
  .p-3 {
    padding: 3rem !important; }
  
  .p-4 {
    padding: 3.5rem !important; }
  
  .p-5 {
    padding: 4rem !important; }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  
  .px-25 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  
  .px-50 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  
  .px-75 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  
  .px-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  
  .px-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  
  .px-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  
  .px-4 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  
  .px-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  
  .py-25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  
  .py-50 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  
  .py-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  
  .py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  
  .py-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  
  .py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  
  .py-4 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  
  .py-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  
  .pt-0 {
    padding-top: 0 !important; }
  
  .pt-25 {
    padding-top: 0.25rem !important; }
  
  .pt-50 {
    padding-top: 0.5rem !important; }
  
  .pt-75 {
    padding-top: 0.75rem !important; }
  
  .pt-1 {
    padding-top: 1rem !important; }
  
  .pt-2 {
    padding-top: 1.5rem !important; }
  
  .pt-3 {
    padding-top: 3rem !important; }
  
  .pt-4 {
    padding-top: 3.5rem !important; }
  
  .pt-5 {
    padding-top: 4rem !important; }
  
  .pe-0 {
    padding-right: 0 !important; }
  
  .pe-25 {
    padding-right: 0.25rem !important; }
  
  .pe-50 {
    padding-right: 0.5rem !important; }
  
  .pe-75 {
    padding-right: 0.75rem !important; }
  
  .pe-1 {
    padding-right: 1rem !important; }
  
  .pe-2 {
    padding-right: 1.5rem !important; }
  
  .pe-3 {
    padding-right: 3rem !important; }
  
  .pe-4 {
    padding-right: 3.5rem !important; }
  
  .pe-5 {
    padding-right: 4rem !important; }
  
  .pb-0 {
    padding-bottom: 0 !important; }
  
  .pb-25 {
    padding-bottom: 0.25rem !important; }
  
  .pb-50 {
    padding-bottom: 0.5rem !important; }
  
  .pb-75 {
    padding-bottom: 0.75rem !important; }
  
  .pb-1 {
    padding-bottom: 1rem !important; }
  
  .pb-2 {
    padding-bottom: 1.5rem !important; }
  
  .pb-3 {
    padding-bottom: 3rem !important; }
  
  .pb-4 {
    padding-bottom: 3.5rem !important; }
  
  .pb-5 {
    padding-bottom: 4rem !important; }
  
  .ps-0 {
    padding-left: 0 !important; }
  
  .ps-25 {
    padding-left: 0.25rem !important; }
  
  .ps-50 {
    padding-left: 0.5rem !important; }
  
  .ps-75 {
    padding-left: 0.75rem !important; }
  
  .ps-1 {
    padding-left: 1rem !important; }
  
  .ps-2 {
    padding-left: 1.5rem !important; }
  
  .ps-3 {
    padding-left: 3rem !important; }
  
  .ps-4 {
    padding-left: 3.5rem !important; }
  
  .ps-5 {
    padding-left: 4rem !important; }
  
  .font-monospace {
    font-family: var(--bs-font-monospace) !important; }
  
  .fs-1 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  
  .fs-2 {
    font-size: calc(1.2964rem + 0.5568vw) !important; }
  
  .fs-3 {
    font-size: calc(1.275rem + 0.3vw) !important; }
  
  .fs-4 {
    font-size: calc(1.2536rem + 0.0432vw) !important; }
  
  .fs-5 {
    font-size: 1.07rem !important; }
  
  .fs-6 {
    font-size: 1rem !important; }
  
  .fst-italic {
    font-style: italic !important; }
  
  .fst-normal {
    font-style: normal !important; }
  
  .fw-light {
    font-weight: 300 !important; }
  
  .fw-lighter {
    font-weight: lighter !important; }
  
  .fw-normal {
    font-weight: 400 !important; }
  
  .fw-bold {
    font-weight: 500 !important; }
  
  .fw-bolder {
    font-weight: 600 !important; }
  
  .lh-1 {
    line-height: 1 !important; }
  
  .lh-sm {
    line-height: 1 !important; }
  
  .lh-base {
    line-height: 1.45 !important; }
  
  .lh-lg {
    line-height: 1.25 !important; }
  
  .text-start {
    text-align: left !important; }
  
  .text-end {
    text-align: right !important; }
  
  .text-center {
    text-align: center !important; }
  
  .text-decoration-none {
    text-decoration: none !important; }
  
  .text-decoration-underline {
    text-decoration: underline !important; }
  
  .text-decoration-line-through {
    text-decoration: line-through !important; }
  
  .text-lowercase {
    text-transform: lowercase !important; }
  
  .text-uppercase {
    text-transform: uppercase !important; }
  
  .text-capitalize {
    text-transform: capitalize !important; }
  
  .text-wrap {
    white-space: normal !important; }
  
  .text-nowrap {
    white-space: nowrap !important; }
  
  /* rtl:begin:remove */
  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important; }
  
  /* rtl:end:remove */
  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }
  
  .text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }
  
  .text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }
  
  .text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }
  
  .text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }
  
  .text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }
  
  .text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }
  
  .text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }
  
  .text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }
  
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }
  
  .text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important; }
  
  .text-muted {
    --bs-text-opacity: 1;
    color: #b9b9c3 !important; }
  
  .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(34, 41, 47, 0.5) !important; }
  
  .text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, 0.5) !important; }
  
  .text-reset {
    --bs-text-opacity: 1;
    color: inherit !important; }
  
  .text-opacity-25 {
    --bs-text-opacity: 0.25; }
  
  .text-opacity-50 {
    --bs-text-opacity: 0.5; }
  
  .text-opacity-75 {
    --bs-text-opacity: 0.75; }
  
  .text-opacity-100 {
    --bs-text-opacity: 1; }
  
  .bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important; }
  
  .bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important; }
  
  .bg-opacity-10 {
    --bs-bg-opacity: 0.1; }
  
  .bg-opacity-25 {
    --bs-bg-opacity: 0.25; }
  
  .bg-opacity-50 {
    --bs-bg-opacity: 0.5; }
  
  .bg-opacity-75 {
    --bs-bg-opacity: 0.75; }
  
  .bg-opacity-100 {
    --bs-bg-opacity: 1; }
  
  .bg-gradient {
    background-image: var(--bs-gradient) !important; }
  
  .user-select-all {
    user-select: all !important; }
  
  .user-select-auto {
    user-select: auto !important; }
  
  .user-select-none {
    user-select: none !important; }
  
  .pe-none {
    pointer-events: none !important; }
  
  .pe-auto {
    pointer-events: auto !important; }
  
  .rounded {
    border-radius: 0.357rem !important; }
  
  .rounded-0 {
    border-radius: 0 !important; }
  
  .rounded-1 {
    border-radius: 0.25rem !important; }
  
  .rounded-2 {
    border-radius: 0.357rem !important; }
  
  .rounded-3 {
    border-radius: 0.6rem !important; }
  
  .rounded-circle {
    border-radius: 50% !important; }
  
  .rounded-pill {
    border-radius: 50rem !important; }
  
  .rounded-top {
    border-top-left-radius: 0.357rem !important;
    border-top-right-radius: 0.357rem !important; }
  
  .rounded-end {
    border-top-right-radius: 0.357rem !important;
    border-bottom-right-radius: 0.357rem !important; }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.357rem !important;
    border-bottom-left-radius: 0.357rem !important; }
  
  .rounded-start {
    border-bottom-left-radius: 0.357rem !important;
    border-top-left-radius: 0.357rem !important; }
  
  .visible {
    visibility: visible !important; }
  
  .invisible {
    visibility: hidden !important; }
  
  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important; }
    .float-sm-end {
      float: right !important; }
    .float-sm-none {
      float: none !important; }
    .d-sm-inline {
      display: inline !important; }
    .d-sm-inline-block {
      display: inline-block !important; }
    .d-sm-block {
      display: block !important; }
    .d-sm-grid {
      display: grid !important; }
    .d-sm-table {
      display: table !important; }
    .d-sm-table-row {
      display: table-row !important; }
    .d-sm-table-cell {
      display: table-cell !important; }
    .d-sm-flex {
      display: flex !important; }
    .d-sm-inline-flex {
      display: inline-flex !important; }
    .d-sm-none {
      display: none !important; }
    .flex-sm-fill {
      flex: 1 1 auto !important; }
    .flex-sm-row {
      flex-direction: row !important; }
    .flex-sm-column {
      flex-direction: column !important; }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-sm-wrap {
      flex-wrap: wrap !important; }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-sm-0 {
      gap: 0 !important; }
    .gap-sm-25 {
      gap: 0.25rem !important; }
    .gap-sm-50 {
      gap: 0.5rem !important; }
    .gap-sm-75 {
      gap: 0.75rem !important; }
    .gap-sm-1 {
      gap: 1rem !important; }
    .gap-sm-2 {
      gap: 1.5rem !important; }
    .gap-sm-3 {
      gap: 3rem !important; }
    .gap-sm-4 {
      gap: 3.5rem !important; }
    .gap-sm-5 {
      gap: 4rem !important; }
    .justify-content-sm-start {
      justify-content: flex-start !important; }
    .justify-content-sm-end {
      justify-content: flex-end !important; }
    .justify-content-sm-center {
      justify-content: center !important; }
    .justify-content-sm-between {
      justify-content: space-between !important; }
    .justify-content-sm-around {
      justify-content: space-around !important; }
    .justify-content-sm-evenly {
      justify-content: space-evenly !important; }
    .align-items-sm-start {
      align-items: flex-start !important; }
    .align-items-sm-end {
      align-items: flex-end !important; }
    .align-items-sm-center {
      align-items: center !important; }
    .align-items-sm-baseline {
      align-items: baseline !important; }
    .align-items-sm-stretch {
      align-items: stretch !important; }
    .align-content-sm-start {
      align-content: flex-start !important; }
    .align-content-sm-end {
      align-content: flex-end !important; }
    .align-content-sm-center {
      align-content: center !important; }
    .align-content-sm-between {
      align-content: space-between !important; }
    .align-content-sm-around {
      align-content: space-around !important; }
    .align-content-sm-stretch {
      align-content: stretch !important; }
    .align-self-sm-auto {
      align-self: auto !important; }
    .align-self-sm-start {
      align-self: flex-start !important; }
    .align-self-sm-end {
      align-self: flex-end !important; }
    .align-self-sm-center {
      align-self: center !important; }
    .align-self-sm-baseline {
      align-self: baseline !important; }
    .align-self-sm-stretch {
      align-self: stretch !important; }
    .order-sm-first {
      order: -1 !important; }
    .order-sm-0 {
      order: 0 !important; }
    .order-sm-1 {
      order: 1 !important; }
    .order-sm-2 {
      order: 2 !important; }
    .order-sm-3 {
      order: 3 !important; }
    .order-sm-4 {
      order: 4 !important; }
    .order-sm-5 {
      order: 5 !important; }
    .order-sm-last {
      order: 6 !important; }
    .m-sm-0 {
      margin: 0 !important; }
    .m-sm-25 {
      margin: 0.25rem !important; }
    .m-sm-50 {
      margin: 0.5rem !important; }
    .m-sm-75 {
      margin: 0.75rem !important; }
    .m-sm-1 {
      margin: 1rem !important; }
    .m-sm-2 {
      margin: 1.5rem !important; }
    .m-sm-3 {
      margin: 3rem !important; }
    .m-sm-4 {
      margin: 3.5rem !important; }
    .m-sm-5 {
      margin: 4rem !important; }
    .m-sm-auto {
      margin: auto !important; }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-sm-25 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .mx-sm-50 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-sm-75 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important; }
    .mx-sm-1 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-sm-2 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .mx-sm-3 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-sm-4 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important; }
    .mx-sm-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important; }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-sm-25 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .my-sm-50 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-sm-75 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important; }
    .my-sm-1 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-sm-2 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .my-sm-3 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-sm-4 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important; }
    .my-sm-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important; }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-sm-0 {
      margin-top: 0 !important; }
    .mt-sm-25 {
      margin-top: 0.25rem !important; }
    .mt-sm-50 {
      margin-top: 0.5rem !important; }
    .mt-sm-75 {
      margin-top: 0.75rem !important; }
    .mt-sm-1 {
      margin-top: 1rem !important; }
    .mt-sm-2 {
      margin-top: 1.5rem !important; }
    .mt-sm-3 {
      margin-top: 3rem !important; }
    .mt-sm-4 {
      margin-top: 3.5rem !important; }
    .mt-sm-5 {
      margin-top: 4rem !important; }
    .mt-sm-auto {
      margin-top: auto !important; }
    .me-sm-0 {
      margin-right: 0 !important; }
    .me-sm-25 {
      margin-right: 0.25rem !important; }
    .me-sm-50 {
      margin-right: 0.5rem !important; }
    .me-sm-75 {
      margin-right: 0.75rem !important; }
    .me-sm-1 {
      margin-right: 1rem !important; }
    .me-sm-2 {
      margin-right: 1.5rem !important; }
    .me-sm-3 {
      margin-right: 3rem !important; }
    .me-sm-4 {
      margin-right: 3.5rem !important; }
    .me-sm-5 {
      margin-right: 4rem !important; }
    .me-sm-auto {
      margin-right: auto !important; }
    .mb-sm-0 {
      margin-bottom: 0 !important; }
    .mb-sm-25 {
      margin-bottom: 0.25rem !important; }
    .mb-sm-50 {
      margin-bottom: 0.5rem !important; }
    .mb-sm-75 {
      margin-bottom: 0.75rem !important; }
    .mb-sm-1 {
      margin-bottom: 1rem !important; }
    .mb-sm-2 {
      margin-bottom: 1.5rem !important; }
    .mb-sm-3 {
      margin-bottom: 3rem !important; }
    .mb-sm-4 {
      margin-bottom: 3.5rem !important; }
    .mb-sm-5 {
      margin-bottom: 4rem !important; }
    .mb-sm-auto {
      margin-bottom: auto !important; }
    .ms-sm-0 {
      margin-left: 0 !important; }
    .ms-sm-25 {
      margin-left: 0.25rem !important; }
    .ms-sm-50 {
      margin-left: 0.5rem !important; }
    .ms-sm-75 {
      margin-left: 0.75rem !important; }
    .ms-sm-1 {
      margin-left: 1rem !important; }
    .ms-sm-2 {
      margin-left: 1.5rem !important; }
    .ms-sm-3 {
      margin-left: 3rem !important; }
    .ms-sm-4 {
      margin-left: 3.5rem !important; }
    .ms-sm-5 {
      margin-left: 4rem !important; }
    .ms-sm-auto {
      margin-left: auto !important; }
    .p-sm-0 {
      padding: 0 !important; }
    .p-sm-25 {
      padding: 0.25rem !important; }
    .p-sm-50 {
      padding: 0.5rem !important; }
    .p-sm-75 {
      padding: 0.75rem !important; }
    .p-sm-1 {
      padding: 1rem !important; }
    .p-sm-2 {
      padding: 1.5rem !important; }
    .p-sm-3 {
      padding: 3rem !important; }
    .p-sm-4 {
      padding: 3.5rem !important; }
    .p-sm-5 {
      padding: 4rem !important; }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-sm-25 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .px-sm-50 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-sm-75 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; }
    .px-sm-1 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-sm-2 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .px-sm-3 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-sm-4 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important; }
    .px-sm-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important; }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-sm-25 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .py-sm-50 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-sm-75 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important; }
    .py-sm-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-sm-2 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .py-sm-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-sm-4 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important; }
    .py-sm-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important; }
    .pt-sm-0 {
      padding-top: 0 !important; }
    .pt-sm-25 {
      padding-top: 0.25rem !important; }
    .pt-sm-50 {
      padding-top: 0.5rem !important; }
    .pt-sm-75 {
      padding-top: 0.75rem !important; }
    .pt-sm-1 {
      padding-top: 1rem !important; }
    .pt-sm-2 {
      padding-top: 1.5rem !important; }
    .pt-sm-3 {
      padding-top: 3rem !important; }
    .pt-sm-4 {
      padding-top: 3.5rem !important; }
    .pt-sm-5 {
      padding-top: 4rem !important; }
    .pe-sm-0 {
      padding-right: 0 !important; }
    .pe-sm-25 {
      padding-right: 0.25rem !important; }
    .pe-sm-50 {
      padding-right: 0.5rem !important; }
    .pe-sm-75 {
      padding-right: 0.75rem !important; }
    .pe-sm-1 {
      padding-right: 1rem !important; }
    .pe-sm-2 {
      padding-right: 1.5rem !important; }
    .pe-sm-3 {
      padding-right: 3rem !important; }
    .pe-sm-4 {
      padding-right: 3.5rem !important; }
    .pe-sm-5 {
      padding-right: 4rem !important; }
    .pb-sm-0 {
      padding-bottom: 0 !important; }
    .pb-sm-25 {
      padding-bottom: 0.25rem !important; }
    .pb-sm-50 {
      padding-bottom: 0.5rem !important; }
    .pb-sm-75 {
      padding-bottom: 0.75rem !important; }
    .pb-sm-1 {
      padding-bottom: 1rem !important; }
    .pb-sm-2 {
      padding-bottom: 1.5rem !important; }
    .pb-sm-3 {
      padding-bottom: 3rem !important; }
    .pb-sm-4 {
      padding-bottom: 3.5rem !important; }
    .pb-sm-5 {
      padding-bottom: 4rem !important; }
    .ps-sm-0 {
      padding-left: 0 !important; }
    .ps-sm-25 {
      padding-left: 0.25rem !important; }
    .ps-sm-50 {
      padding-left: 0.5rem !important; }
    .ps-sm-75 {
      padding-left: 0.75rem !important; }
    .ps-sm-1 {
      padding-left: 1rem !important; }
    .ps-sm-2 {
      padding-left: 1.5rem !important; }
    .ps-sm-3 {
      padding-left: 3rem !important; }
    .ps-sm-4 {
      padding-left: 3.5rem !important; }
    .ps-sm-5 {
      padding-left: 4rem !important; }
    .text-sm-start {
      text-align: left !important; }
    .text-sm-end {
      text-align: right !important; }
    .text-sm-center {
      text-align: center !important; } }
  
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important; }
    .float-md-end {
      float: right !important; }
    .float-md-none {
      float: none !important; }
    .d-md-inline {
      display: inline !important; }
    .d-md-inline-block {
      display: inline-block !important; }
    .d-md-block {
      display: block !important; }
    .d-md-grid {
      display: grid !important; }
    .d-md-table {
      display: table !important; }
    .d-md-table-row {
      display: table-row !important; }
    .d-md-table-cell {
      display: table-cell !important; }
    .d-md-flex {
      display: flex !important; }
    .d-md-inline-flex {
      display: inline-flex !important; }
    .d-md-none {
      display: none !important; }
    .flex-md-fill {
      flex: 1 1 auto !important; }
    .flex-md-row {
      flex-direction: row !important; }
    .flex-md-column {
      flex-direction: column !important; }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-md-grow-0 {
      flex-grow: 0 !important; }
    .flex-md-grow-1 {
      flex-grow: 1 !important; }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-md-wrap {
      flex-wrap: wrap !important; }
    .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-md-0 {
      gap: 0 !important; }
    .gap-md-25 {
      gap: 0.25rem !important; }
    .gap-md-50 {
      gap: 0.5rem !important; }
    .gap-md-75 {
      gap: 0.75rem !important; }
    .gap-md-1 {
      gap: 1rem !important; }
    .gap-md-2 {
      gap: 1.5rem !important; }
    .gap-md-3 {
      gap: 3rem !important; }
    .gap-md-4 {
      gap: 3.5rem !important; }
    .gap-md-5 {
      gap: 4rem !important; }
    .justify-content-md-start {
      justify-content: flex-start !important; }
    .justify-content-md-end {
      justify-content: flex-end !important; }
    .justify-content-md-center {
      justify-content: center !important; }
    .justify-content-md-between {
      justify-content: space-between !important; }
    .justify-content-md-around {
      justify-content: space-around !important; }
    .justify-content-md-evenly {
      justify-content: space-evenly !important; }
    .align-items-md-start {
      align-items: flex-start !important; }
    .align-items-md-end {
      align-items: flex-end !important; }
    .align-items-md-center {
      align-items: center !important; }
    .align-items-md-baseline {
      align-items: baseline !important; }
    .align-items-md-stretch {
      align-items: stretch !important; }
    .align-content-md-start {
      align-content: flex-start !important; }
    .align-content-md-end {
      align-content: flex-end !important; }
    .align-content-md-center {
      align-content: center !important; }
    .align-content-md-between {
      align-content: space-between !important; }
    .align-content-md-around {
      align-content: space-around !important; }
    .align-content-md-stretch {
      align-content: stretch !important; }
    .align-self-md-auto {
      align-self: auto !important; }
    .align-self-md-start {
      align-self: flex-start !important; }
    .align-self-md-end {
      align-self: flex-end !important; }
    .align-self-md-center {
      align-self: center !important; }
    .align-self-md-baseline {
      align-self: baseline !important; }
    .align-self-md-stretch {
      align-self: stretch !important; }
    .order-md-first {
      order: -1 !important; }
    .order-md-0 {
      order: 0 !important; }
    .order-md-1 {
      order: 1 !important; }
    .order-md-2 {
      order: 2 !important; }
    .order-md-3 {
      order: 3 !important; }
    .order-md-4 {
      order: 4 !important; }
    .order-md-5 {
      order: 5 !important; }
    .order-md-last {
      order: 6 !important; }
    .m-md-0 {
      margin: 0 !important; }
    .m-md-25 {
      margin: 0.25rem !important; }
    .m-md-50 {
      margin: 0.5rem !important; }
    .m-md-75 {
      margin: 0.75rem !important; }
    .m-md-1 {
      margin: 1rem !important; }
    .m-md-2 {
      margin: 1.5rem !important; }
    .m-md-3 {
      margin: 3rem !important; }
    .m-md-4 {
      margin: 3.5rem !important; }
    .m-md-5 {
      margin: 4rem !important; }
    .m-md-auto {
      margin: auto !important; }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-md-25 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .mx-md-50 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-md-75 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important; }
    .mx-md-1 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-md-2 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .mx-md-3 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-md-4 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important; }
    .mx-md-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important; }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-md-25 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .my-md-50 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-md-75 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important; }
    .my-md-1 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-md-2 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .my-md-3 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-md-4 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important; }
    .my-md-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important; }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-md-0 {
      margin-top: 0 !important; }
    .mt-md-25 {
      margin-top: 0.25rem !important; }
    .mt-md-50 {
      margin-top: 0.5rem !important; }
    .mt-md-75 {
      margin-top: 0.75rem !important; }
    .mt-md-1 {
      margin-top: 1rem !important; }
    .mt-md-2 {
      margin-top: 1.5rem !important; }
    .mt-md-3 {
      margin-top: 3rem !important; }
    .mt-md-4 {
      margin-top: 3.5rem !important; }
    .mt-md-5 {
      margin-top: 4rem !important; }
    .mt-md-auto {
      margin-top: auto !important; }
    .me-md-0 {
      margin-right: 0 !important; }
    .me-md-25 {
      margin-right: 0.25rem !important; }
    .me-md-50 {
      margin-right: 0.5rem !important; }
    .me-md-75 {
      margin-right: 0.75rem !important; }
    .me-md-1 {
      margin-right: 1rem !important; }
    .me-md-2 {
      margin-right: 1.5rem !important; }
    .me-md-3 {
      margin-right: 3rem !important; }
    .me-md-4 {
      margin-right: 3.5rem !important; }
    .me-md-5 {
      margin-right: 4rem !important; }
    .me-md-auto {
      margin-right: auto !important; }
    .mb-md-0 {
      margin-bottom: 0 !important; }
    .mb-md-25 {
      margin-bottom: 0.25rem !important; }
    .mb-md-50 {
      margin-bottom: 0.5rem !important; }
    .mb-md-75 {
      margin-bottom: 0.75rem !important; }
    .mb-md-1 {
      margin-bottom: 1rem !important; }
    .mb-md-2 {
      margin-bottom: 1.5rem !important; }
    .mb-md-3 {
      margin-bottom: 3rem !important; }
    .mb-md-4 {
      margin-bottom: 3.5rem !important; }
    .mb-md-5 {
      margin-bottom: 4rem !important; }
    .mb-md-auto {
      margin-bottom: auto !important; }
    .ms-md-0 {
      margin-left: 0 !important; }
    .ms-md-25 {
      margin-left: 0.25rem !important; }
    .ms-md-50 {
      margin-left: 0.5rem !important; }
    .ms-md-75 {
      margin-left: 0.75rem !important; }
    .ms-md-1 {
      margin-left: 1rem !important; }
    .ms-md-2 {
      margin-left: 1.5rem !important; }
    .ms-md-3 {
      margin-left: 3rem !important; }
    .ms-md-4 {
      margin-left: 3.5rem !important; }
    .ms-md-5 {
      margin-left: 4rem !important; }
    .ms-md-auto {
      margin-left: auto !important; }
    .p-md-0 {
      padding: 0 !important; }
    .p-md-25 {
      padding: 0.25rem !important; }
    .p-md-50 {
      padding: 0.5rem !important; }
    .p-md-75 {
      padding: 0.75rem !important; }
    .p-md-1 {
      padding: 1rem !important; }
    .p-md-2 {
      padding: 1.5rem !important; }
    .p-md-3 {
      padding: 3rem !important; }
    .p-md-4 {
      padding: 3.5rem !important; }
    .p-md-5 {
      padding: 4rem !important; }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-md-25 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .px-md-50 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-md-75 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; }
    .px-md-1 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-md-2 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .px-md-3 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-md-4 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important; }
    .px-md-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important; }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-md-25 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .py-md-50 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-md-75 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important; }
    .py-md-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-md-2 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .py-md-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-md-4 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important; }
    .py-md-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important; }
    .pt-md-0 {
      padding-top: 0 !important; }
    .pt-md-25 {
      padding-top: 0.25rem !important; }
    .pt-md-50 {
      padding-top: 0.5rem !important; }
    .pt-md-75 {
      padding-top: 0.75rem !important; }
    .pt-md-1 {
      padding-top: 1rem !important; }
    .pt-md-2 {
      padding-top: 1.5rem !important; }
    .pt-md-3 {
      padding-top: 3rem !important; }
    .pt-md-4 {
      padding-top: 3.5rem !important; }
    .pt-md-5 {
      padding-top: 4rem !important; }
    .pe-md-0 {
      padding-right: 0 !important; }
    .pe-md-25 {
      padding-right: 0.25rem !important; }
    .pe-md-50 {
      padding-right: 0.5rem !important; }
    .pe-md-75 {
      padding-right: 0.75rem !important; }
    .pe-md-1 {
      padding-right: 1rem !important; }
    .pe-md-2 {
      padding-right: 1.5rem !important; }
    .pe-md-3 {
      padding-right: 3rem !important; }
    .pe-md-4 {
      padding-right: 3.5rem !important; }
    .pe-md-5 {
      padding-right: 4rem !important; }
    .pb-md-0 {
      padding-bottom: 0 !important; }
    .pb-md-25 {
      padding-bottom: 0.25rem !important; }
    .pb-md-50 {
      padding-bottom: 0.5rem !important; }
    .pb-md-75 {
      padding-bottom: 0.75rem !important; }
    .pb-md-1 {
      padding-bottom: 1rem !important; }
    .pb-md-2 {
      padding-bottom: 1.5rem !important; }
    .pb-md-3 {
      padding-bottom: 3rem !important; }
    .pb-md-4 {
      padding-bottom: 3.5rem !important; }
    .pb-md-5 {
      padding-bottom: 4rem !important; }
    .ps-md-0 {
      padding-left: 0 !important; }
    .ps-md-25 {
      padding-left: 0.25rem !important; }
    .ps-md-50 {
      padding-left: 0.5rem !important; }
    .ps-md-75 {
      padding-left: 0.75rem !important; }
    .ps-md-1 {
      padding-left: 1rem !important; }
    .ps-md-2 {
      padding-left: 1.5rem !important; }
    .ps-md-3 {
      padding-left: 3rem !important; }
    .ps-md-4 {
      padding-left: 3.5rem !important; }
    .ps-md-5 {
      padding-left: 4rem !important; }
    .text-md-start {
      text-align: left !important; }
    .text-md-end {
      text-align: right !important; }
    .text-md-center {
      text-align: center !important; } }
  
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important; }
    .float-lg-end {
      float: right !important; }
    .float-lg-none {
      float: none !important; }
    .d-lg-inline {
      display: inline !important; }
    .d-lg-inline-block {
      display: inline-block !important; }
    .d-lg-block {
      display: block !important; }
    .d-lg-grid {
      display: grid !important; }
    .d-lg-table {
      display: table !important; }
    .d-lg-table-row {
      display: table-row !important; }
    .d-lg-table-cell {
      display: table-cell !important; }
    .d-lg-flex {
      display: flex !important; }
    .d-lg-inline-flex {
      display: inline-flex !important; }
    .d-lg-none {
      display: none !important; }
    .flex-lg-fill {
      flex: 1 1 auto !important; }
    .flex-lg-row {
      flex-direction: row !important; }
    .flex-lg-column {
      flex-direction: column !important; }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-lg-wrap {
      flex-wrap: wrap !important; }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-lg-0 {
      gap: 0 !important; }
    .gap-lg-25 {
      gap: 0.25rem !important; }
    .gap-lg-50 {
      gap: 0.5rem !important; }
    .gap-lg-75 {
      gap: 0.75rem !important; }
    .gap-lg-1 {
      gap: 1rem !important; }
    .gap-lg-2 {
      gap: 1.5rem !important; }
    .gap-lg-3 {
      gap: 3rem !important; }
    .gap-lg-4 {
      gap: 3.5rem !important; }
    .gap-lg-5 {
      gap: 4rem !important; }
    .justify-content-lg-start {
      justify-content: flex-start !important; }
    .justify-content-lg-end {
      justify-content: flex-end !important; }
    .justify-content-lg-center {
      justify-content: center !important; }
    .justify-content-lg-between {
      justify-content: space-between !important; }
    .justify-content-lg-around {
      justify-content: space-around !important; }
    .justify-content-lg-evenly {
      justify-content: space-evenly !important; }
    .align-items-lg-start {
      align-items: flex-start !important; }
    .align-items-lg-end {
      align-items: flex-end !important; }
    .align-items-lg-center {
      align-items: center !important; }
    .align-items-lg-baseline {
      align-items: baseline !important; }
    .align-items-lg-stretch {
      align-items: stretch !important; }
    .align-content-lg-start {
      align-content: flex-start !important; }
    .align-content-lg-end {
      align-content: flex-end !important; }
    .align-content-lg-center {
      align-content: center !important; }
    .align-content-lg-between {
      align-content: space-between !important; }
    .align-content-lg-around {
      align-content: space-around !important; }
    .align-content-lg-stretch {
      align-content: stretch !important; }
    .align-self-lg-auto {
      align-self: auto !important; }
    .align-self-lg-start {
      align-self: flex-start !important; }
    .align-self-lg-end {
      align-self: flex-end !important; }
    .align-self-lg-center {
      align-self: center !important; }
    .align-self-lg-baseline {
      align-self: baseline !important; }
    .align-self-lg-stretch {
      align-self: stretch !important; }
    .order-lg-first {
      order: -1 !important; }
    .order-lg-0 {
      order: 0 !important; }
    .order-lg-1 {
      order: 1 !important; }
    .order-lg-2 {
      order: 2 !important; }
    .order-lg-3 {
      order: 3 !important; }
    .order-lg-4 {
      order: 4 !important; }
    .order-lg-5 {
      order: 5 !important; }
    .order-lg-last {
      order: 6 !important; }
    .m-lg-0 {
      margin: 0 !important; }
    .m-lg-25 {
      margin: 0.25rem !important; }
    .m-lg-50 {
      margin: 0.5rem !important; }
    .m-lg-75 {
      margin: 0.75rem !important; }
    .m-lg-1 {
      margin: 1rem !important; }
    .m-lg-2 {
      margin: 1.5rem !important; }
    .m-lg-3 {
      margin: 3rem !important; }
    .m-lg-4 {
      margin: 3.5rem !important; }
    .m-lg-5 {
      margin: 4rem !important; }
    .m-lg-auto {
      margin: auto !important; }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-lg-25 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .mx-lg-50 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-lg-75 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important; }
    .mx-lg-1 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-lg-2 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .mx-lg-3 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-lg-4 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important; }
    .mx-lg-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important; }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-lg-25 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .my-lg-50 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-lg-75 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important; }
    .my-lg-1 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-lg-2 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .my-lg-3 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-lg-4 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important; }
    .my-lg-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important; }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-lg-0 {
      margin-top: 0 !important; }
    .mt-lg-25 {
      margin-top: 0.25rem !important; }
    .mt-lg-50 {
      margin-top: 0.5rem !important; }
    .mt-lg-75 {
      margin-top: 0.75rem !important; }
    .mt-lg-1 {
      margin-top: 1rem !important; }
    .mt-lg-2 {
      margin-top: 1.5rem !important; }
    .mt-lg-3 {
      margin-top: 3rem !important; }
    .mt-lg-4 {
      margin-top: 3.5rem !important; }
    .mt-lg-5 {
      margin-top: 4rem !important; }
    .mt-lg-auto {
      margin-top: auto !important; }
    .me-lg-0 {
      margin-right: 0 !important; }
    .me-lg-25 {
      margin-right: 0.25rem !important; }
    .me-lg-50 {
      margin-right: 0.5rem !important; }
    .me-lg-75 {
      margin-right: 0.75rem !important; }
    .me-lg-1 {
      margin-right: 1rem !important; }
    .me-lg-2 {
      margin-right: 1.5rem !important; }
    .me-lg-3 {
      margin-right: 3rem !important; }
    .me-lg-4 {
      margin-right: 3.5rem !important; }
    .me-lg-5 {
      margin-right: 4rem !important; }
    .me-lg-auto {
      margin-right: auto !important; }
    .mb-lg-0 {
      margin-bottom: 0 !important; }
    .mb-lg-25 {
      margin-bottom: 0.25rem !important; }
    .mb-lg-50 {
      margin-bottom: 0.5rem !important; }
    .mb-lg-75 {
      margin-bottom: 0.75rem !important; }
    .mb-lg-1 {
      margin-bottom: 1rem !important; }
    .mb-lg-2 {
      margin-bottom: 1.5rem !important; }
    .mb-lg-3 {
      margin-bottom: 3rem !important; }
    .mb-lg-4 {
      margin-bottom: 3.5rem !important; }
    .mb-lg-5 {
      margin-bottom: 4rem !important; }
    .mb-lg-auto {
      margin-bottom: auto !important; }
    .ms-lg-0 {
      margin-left: 0 !important; }
    .ms-lg-25 {
      margin-left: 0.25rem !important; }
    .ms-lg-50 {
      margin-left: 0.5rem !important; }
    .ms-lg-75 {
      margin-left: 0.75rem !important; }
    .ms-lg-1 {
      margin-left: 1rem !important; }
    .ms-lg-2 {
      margin-left: 1.5rem !important; }
    .ms-lg-3 {
      margin-left: 3rem !important; }
    .ms-lg-4 {
      margin-left: 3.5rem !important; }
    .ms-lg-5 {
      margin-left: 4rem !important; }
    .ms-lg-auto {
      margin-left: auto !important; }
    .p-lg-0 {
      padding: 0 !important; }
    .p-lg-25 {
      padding: 0.25rem !important; }
    .p-lg-50 {
      padding: 0.5rem !important; }
    .p-lg-75 {
      padding: 0.75rem !important; }
    .p-lg-1 {
      padding: 1rem !important; }
    .p-lg-2 {
      padding: 1.5rem !important; }
    .p-lg-3 {
      padding: 3rem !important; }
    .p-lg-4 {
      padding: 3.5rem !important; }
    .p-lg-5 {
      padding: 4rem !important; }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-lg-25 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .px-lg-50 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-lg-75 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; }
    .px-lg-1 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-lg-2 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .px-lg-3 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-lg-4 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important; }
    .px-lg-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important; }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-lg-25 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .py-lg-50 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-lg-75 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important; }
    .py-lg-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-lg-2 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .py-lg-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-lg-4 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important; }
    .py-lg-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important; }
    .pt-lg-0 {
      padding-top: 0 !important; }
    .pt-lg-25 {
      padding-top: 0.25rem !important; }
    .pt-lg-50 {
      padding-top: 0.5rem !important; }
    .pt-lg-75 {
      padding-top: 0.75rem !important; }
    .pt-lg-1 {
      padding-top: 1rem !important; }
    .pt-lg-2 {
      padding-top: 1.5rem !important; }
    .pt-lg-3 {
      padding-top: 3rem !important; }
    .pt-lg-4 {
      padding-top: 3.5rem !important; }
    .pt-lg-5 {
      padding-top: 4rem !important; }
    .pe-lg-0 {
      padding-right: 0 !important; }
    .pe-lg-25 {
      padding-right: 0.25rem !important; }
    .pe-lg-50 {
      padding-right: 0.5rem !important; }
    .pe-lg-75 {
      padding-right: 0.75rem !important; }
    .pe-lg-1 {
      padding-right: 1rem !important; }
    .pe-lg-2 {
      padding-right: 1.5rem !important; }
    .pe-lg-3 {
      padding-right: 3rem !important; }
    .pe-lg-4 {
      padding-right: 3.5rem !important; }
    .pe-lg-5 {
      padding-right: 4rem !important; }
    .pb-lg-0 {
      padding-bottom: 0 !important; }
    .pb-lg-25 {
      padding-bottom: 0.25rem !important; }
    .pb-lg-50 {
      padding-bottom: 0.5rem !important; }
    .pb-lg-75 {
      padding-bottom: 0.75rem !important; }
    .pb-lg-1 {
      padding-bottom: 1rem !important; }
    .pb-lg-2 {
      padding-bottom: 1.5rem !important; }
    .pb-lg-3 {
      padding-bottom: 3rem !important; }
    .pb-lg-4 {
      padding-bottom: 3.5rem !important; }
    .pb-lg-5 {
      padding-bottom: 4rem !important; }
    .ps-lg-0 {
      padding-left: 0 !important; }
    .ps-lg-25 {
      padding-left: 0.25rem !important; }
    .ps-lg-50 {
      padding-left: 0.5rem !important; }
    .ps-lg-75 {
      padding-left: 0.75rem !important; }
    .ps-lg-1 {
      padding-left: 1rem !important; }
    .ps-lg-2 {
      padding-left: 1.5rem !important; }
    .ps-lg-3 {
      padding-left: 3rem !important; }
    .ps-lg-4 {
      padding-left: 3.5rem !important; }
    .ps-lg-5 {
      padding-left: 4rem !important; }
    .text-lg-start {
      text-align: left !important; }
    .text-lg-end {
      text-align: right !important; }
    .text-lg-center {
      text-align: center !important; } }
  
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important; }
    .float-xl-end {
      float: right !important; }
    .float-xl-none {
      float: none !important; }
    .d-xl-inline {
      display: inline !important; }
    .d-xl-inline-block {
      display: inline-block !important; }
    .d-xl-block {
      display: block !important; }
    .d-xl-grid {
      display: grid !important; }
    .d-xl-table {
      display: table !important; }
    .d-xl-table-row {
      display: table-row !important; }
    .d-xl-table-cell {
      display: table-cell !important; }
    .d-xl-flex {
      display: flex !important; }
    .d-xl-inline-flex {
      display: inline-flex !important; }
    .d-xl-none {
      display: none !important; }
    .flex-xl-fill {
      flex: 1 1 auto !important; }
    .flex-xl-row {
      flex-direction: row !important; }
    .flex-xl-column {
      flex-direction: column !important; }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-xl-wrap {
      flex-wrap: wrap !important; }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-xl-0 {
      gap: 0 !important; }
    .gap-xl-25 {
      gap: 0.25rem !important; }
    .gap-xl-50 {
      gap: 0.5rem !important; }
    .gap-xl-75 {
      gap: 0.75rem !important; }
    .gap-xl-1 {
      gap: 1rem !important; }
    .gap-xl-2 {
      gap: 1.5rem !important; }
    .gap-xl-3 {
      gap: 3rem !important; }
    .gap-xl-4 {
      gap: 3.5rem !important; }
    .gap-xl-5 {
      gap: 4rem !important; }
    .justify-content-xl-start {
      justify-content: flex-start !important; }
    .justify-content-xl-end {
      justify-content: flex-end !important; }
    .justify-content-xl-center {
      justify-content: center !important; }
    .justify-content-xl-between {
      justify-content: space-between !important; }
    .justify-content-xl-around {
      justify-content: space-around !important; }
    .justify-content-xl-evenly {
      justify-content: space-evenly !important; }
    .align-items-xl-start {
      align-items: flex-start !important; }
    .align-items-xl-end {
      align-items: flex-end !important; }
    .align-items-xl-center {
      align-items: center !important; }
    .align-items-xl-baseline {
      align-items: baseline !important; }
    .align-items-xl-stretch {
      align-items: stretch !important; }
    .align-content-xl-start {
      align-content: flex-start !important; }
    .align-content-xl-end {
      align-content: flex-end !important; }
    .align-content-xl-center {
      align-content: center !important; }
    .align-content-xl-between {
      align-content: space-between !important; }
    .align-content-xl-around {
      align-content: space-around !important; }
    .align-content-xl-stretch {
      align-content: stretch !important; }
    .align-self-xl-auto {
      align-self: auto !important; }
    .align-self-xl-start {
      align-self: flex-start !important; }
    .align-self-xl-end {
      align-self: flex-end !important; }
    .align-self-xl-center {
      align-self: center !important; }
    .align-self-xl-baseline {
      align-self: baseline !important; }
    .align-self-xl-stretch {
      align-self: stretch !important; }
    .order-xl-first {
      order: -1 !important; }
    .order-xl-0 {
      order: 0 !important; }
    .order-xl-1 {
      order: 1 !important; }
    .order-xl-2 {
      order: 2 !important; }
    .order-xl-3 {
      order: 3 !important; }
    .order-xl-4 {
      order: 4 !important; }
    .order-xl-5 {
      order: 5 !important; }
    .order-xl-last {
      order: 6 !important; }
    .m-xl-0 {
      margin: 0 !important; }
    .m-xl-25 {
      margin: 0.25rem !important; }
    .m-xl-50 {
      margin: 0.5rem !important; }
    .m-xl-75 {
      margin: 0.75rem !important; }
    .m-xl-1 {
      margin: 1rem !important; }
    .m-xl-2 {
      margin: 1.5rem !important; }
    .m-xl-3 {
      margin: 3rem !important; }
    .m-xl-4 {
      margin: 3.5rem !important; }
    .m-xl-5 {
      margin: 4rem !important; }
    .m-xl-auto {
      margin: auto !important; }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-xl-25 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .mx-xl-50 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-xl-75 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important; }
    .mx-xl-1 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-xl-2 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .mx-xl-3 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-xl-4 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important; }
    .mx-xl-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important; }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-xl-25 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .my-xl-50 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-xl-75 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important; }
    .my-xl-1 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-xl-2 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .my-xl-3 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-xl-4 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important; }
    .my-xl-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important; }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-xl-0 {
      margin-top: 0 !important; }
    .mt-xl-25 {
      margin-top: 0.25rem !important; }
    .mt-xl-50 {
      margin-top: 0.5rem !important; }
    .mt-xl-75 {
      margin-top: 0.75rem !important; }
    .mt-xl-1 {
      margin-top: 1rem !important; }
    .mt-xl-2 {
      margin-top: 1.5rem !important; }
    .mt-xl-3 {
      margin-top: 3rem !important; }
    .mt-xl-4 {
      margin-top: 3.5rem !important; }
    .mt-xl-5 {
      margin-top: 4rem !important; }
    .mt-xl-auto {
      margin-top: auto !important; }
    .me-xl-0 {
      margin-right: 0 !important; }
    .me-xl-25 {
      margin-right: 0.25rem !important; }
    .me-xl-50 {
      margin-right: 0.5rem !important; }
    .me-xl-75 {
      margin-right: 0.75rem !important; }
    .me-xl-1 {
      margin-right: 1rem !important; }
    .me-xl-2 {
      margin-right: 1.5rem !important; }
    .me-xl-3 {
      margin-right: 3rem !important; }
    .me-xl-4 {
      margin-right: 3.5rem !important; }
    .me-xl-5 {
      margin-right: 4rem !important; }
    .me-xl-auto {
      margin-right: auto !important; }
    .mb-xl-0 {
      margin-bottom: 0 !important; }
    .mb-xl-25 {
      margin-bottom: 0.25rem !important; }
    .mb-xl-50 {
      margin-bottom: 0.5rem !important; }
    .mb-xl-75 {
      margin-bottom: 0.75rem !important; }
    .mb-xl-1 {
      margin-bottom: 1rem !important; }
    .mb-xl-2 {
      margin-bottom: 1.5rem !important; }
    .mb-xl-3 {
      margin-bottom: 3rem !important; }
    .mb-xl-4 {
      margin-bottom: 3.5rem !important; }
    .mb-xl-5 {
      margin-bottom: 4rem !important; }
    .mb-xl-auto {
      margin-bottom: auto !important; }
    .ms-xl-0 {
      margin-left: 0 !important; }
    .ms-xl-25 {
      margin-left: 0.25rem !important; }
    .ms-xl-50 {
      margin-left: 0.5rem !important; }
    .ms-xl-75 {
      margin-left: 0.75rem !important; }
    .ms-xl-1 {
      margin-left: 1rem !important; }
    .ms-xl-2 {
      margin-left: 1.5rem !important; }
    .ms-xl-3 {
      margin-left: 3rem !important; }
    .ms-xl-4 {
      margin-left: 3.5rem !important; }
    .ms-xl-5 {
      margin-left: 4rem !important; }
    .ms-xl-auto {
      margin-left: auto !important; }
    .p-xl-0 {
      padding: 0 !important; }
    .p-xl-25 {
      padding: 0.25rem !important; }
    .p-xl-50 {
      padding: 0.5rem !important; }
    .p-xl-75 {
      padding: 0.75rem !important; }
    .p-xl-1 {
      padding: 1rem !important; }
    .p-xl-2 {
      padding: 1.5rem !important; }
    .p-xl-3 {
      padding: 3rem !important; }
    .p-xl-4 {
      padding: 3.5rem !important; }
    .p-xl-5 {
      padding: 4rem !important; }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-xl-25 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .px-xl-50 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-xl-75 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; }
    .px-xl-1 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-xl-2 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .px-xl-3 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-xl-4 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important; }
    .px-xl-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important; }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-xl-25 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .py-xl-50 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-xl-75 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important; }
    .py-xl-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-xl-2 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .py-xl-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-xl-4 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important; }
    .py-xl-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important; }
    .pt-xl-0 {
      padding-top: 0 !important; }
    .pt-xl-25 {
      padding-top: 0.25rem !important; }
    .pt-xl-50 {
      padding-top: 0.5rem !important; }
    .pt-xl-75 {
      padding-top: 0.75rem !important; }
    .pt-xl-1 {
      padding-top: 1rem !important; }
    .pt-xl-2 {
      padding-top: 1.5rem !important; }
    .pt-xl-3 {
      padding-top: 3rem !important; }
    .pt-xl-4 {
      padding-top: 3.5rem !important; }
    .pt-xl-5 {
      padding-top: 4rem !important; }
    .pe-xl-0 {
      padding-right: 0 !important; }
    .pe-xl-25 {
      padding-right: 0.25rem !important; }
    .pe-xl-50 {
      padding-right: 0.5rem !important; }
    .pe-xl-75 {
      padding-right: 0.75rem !important; }
    .pe-xl-1 {
      padding-right: 1rem !important; }
    .pe-xl-2 {
      padding-right: 1.5rem !important; }
    .pe-xl-3 {
      padding-right: 3rem !important; }
    .pe-xl-4 {
      padding-right: 3.5rem !important; }
    .pe-xl-5 {
      padding-right: 4rem !important; }
    .pb-xl-0 {
      padding-bottom: 0 !important; }
    .pb-xl-25 {
      padding-bottom: 0.25rem !important; }
    .pb-xl-50 {
      padding-bottom: 0.5rem !important; }
    .pb-xl-75 {
      padding-bottom: 0.75rem !important; }
    .pb-xl-1 {
      padding-bottom: 1rem !important; }
    .pb-xl-2 {
      padding-bottom: 1.5rem !important; }
    .pb-xl-3 {
      padding-bottom: 3rem !important; }
    .pb-xl-4 {
      padding-bottom: 3.5rem !important; }
    .pb-xl-5 {
      padding-bottom: 4rem !important; }
    .ps-xl-0 {
      padding-left: 0 !important; }
    .ps-xl-25 {
      padding-left: 0.25rem !important; }
    .ps-xl-50 {
      padding-left: 0.5rem !important; }
    .ps-xl-75 {
      padding-left: 0.75rem !important; }
    .ps-xl-1 {
      padding-left: 1rem !important; }
    .ps-xl-2 {
      padding-left: 1.5rem !important; }
    .ps-xl-3 {
      padding-left: 3rem !important; }
    .ps-xl-4 {
      padding-left: 3.5rem !important; }
    .ps-xl-5 {
      padding-left: 4rem !important; }
    .text-xl-start {
      text-align: left !important; }
    .text-xl-end {
      text-align: right !important; }
    .text-xl-center {
      text-align: center !important; } }
  
  @media (min-width: 1440px) {
    .float-xxl-start {
      float: left !important; }
    .float-xxl-end {
      float: right !important; }
    .float-xxl-none {
      float: none !important; }
    .d-xxl-inline {
      display: inline !important; }
    .d-xxl-inline-block {
      display: inline-block !important; }
    .d-xxl-block {
      display: block !important; }
    .d-xxl-grid {
      display: grid !important; }
    .d-xxl-table {
      display: table !important; }
    .d-xxl-table-row {
      display: table-row !important; }
    .d-xxl-table-cell {
      display: table-cell !important; }
    .d-xxl-flex {
      display: flex !important; }
    .d-xxl-inline-flex {
      display: inline-flex !important; }
    .d-xxl-none {
      display: none !important; }
    .flex-xxl-fill {
      flex: 1 1 auto !important; }
    .flex-xxl-row {
      flex-direction: row !important; }
    .flex-xxl-column {
      flex-direction: column !important; }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important; }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important; }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-xxl-wrap {
      flex-wrap: wrap !important; }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important; }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-xxl-0 {
      gap: 0 !important; }
    .gap-xxl-25 {
      gap: 0.25rem !important; }
    .gap-xxl-50 {
      gap: 0.5rem !important; }
    .gap-xxl-75 {
      gap: 0.75rem !important; }
    .gap-xxl-1 {
      gap: 1rem !important; }
    .gap-xxl-2 {
      gap: 1.5rem !important; }
    .gap-xxl-3 {
      gap: 3rem !important; }
    .gap-xxl-4 {
      gap: 3.5rem !important; }
    .gap-xxl-5 {
      gap: 4rem !important; }
    .justify-content-xxl-start {
      justify-content: flex-start !important; }
    .justify-content-xxl-end {
      justify-content: flex-end !important; }
    .justify-content-xxl-center {
      justify-content: center !important; }
    .justify-content-xxl-between {
      justify-content: space-between !important; }
    .justify-content-xxl-around {
      justify-content: space-around !important; }
    .justify-content-xxl-evenly {
      justify-content: space-evenly !important; }
    .align-items-xxl-start {
      align-items: flex-start !important; }
    .align-items-xxl-end {
      align-items: flex-end !important; }
    .align-items-xxl-center {
      align-items: center !important; }
    .align-items-xxl-baseline {
      align-items: baseline !important; }
    .align-items-xxl-stretch {
      align-items: stretch !important; }
    .align-content-xxl-start {
      align-content: flex-start !important; }
    .align-content-xxl-end {
      align-content: flex-end !important; }
    .align-content-xxl-center {
      align-content: center !important; }
    .align-content-xxl-between {
      align-content: space-between !important; }
    .align-content-xxl-around {
      align-content: space-around !important; }
    .align-content-xxl-stretch {
      align-content: stretch !important; }
    .align-self-xxl-auto {
      align-self: auto !important; }
    .align-self-xxl-start {
      align-self: flex-start !important; }
    .align-self-xxl-end {
      align-self: flex-end !important; }
    .align-self-xxl-center {
      align-self: center !important; }
    .align-self-xxl-baseline {
      align-self: baseline !important; }
    .align-self-xxl-stretch {
      align-self: stretch !important; }
    .order-xxl-first {
      order: -1 !important; }
    .order-xxl-0 {
      order: 0 !important; }
    .order-xxl-1 {
      order: 1 !important; }
    .order-xxl-2 {
      order: 2 !important; }
    .order-xxl-3 {
      order: 3 !important; }
    .order-xxl-4 {
      order: 4 !important; }
    .order-xxl-5 {
      order: 5 !important; }
    .order-xxl-last {
      order: 6 !important; }
    .m-xxl-0 {
      margin: 0 !important; }
    .m-xxl-25 {
      margin: 0.25rem !important; }
    .m-xxl-50 {
      margin: 0.5rem !important; }
    .m-xxl-75 {
      margin: 0.75rem !important; }
    .m-xxl-1 {
      margin: 1rem !important; }
    .m-xxl-2 {
      margin: 1.5rem !important; }
    .m-xxl-3 {
      margin: 3rem !important; }
    .m-xxl-4 {
      margin: 3.5rem !important; }
    .m-xxl-5 {
      margin: 4rem !important; }
    .m-xxl-auto {
      margin: auto !important; }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-xxl-25 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .mx-xxl-50 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-xxl-75 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important; }
    .mx-xxl-1 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-xxl-2 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .mx-xxl-3 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-xxl-4 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important; }
    .mx-xxl-5 {
      margin-right: 4rem !important;
      margin-left: 4rem !important; }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-xxl-25 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .my-xxl-50 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-xxl-75 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important; }
    .my-xxl-1 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-xxl-2 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .my-xxl-3 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-xxl-4 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important; }
    .my-xxl-5 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important; }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-xxl-0 {
      margin-top: 0 !important; }
    .mt-xxl-25 {
      margin-top: 0.25rem !important; }
    .mt-xxl-50 {
      margin-top: 0.5rem !important; }
    .mt-xxl-75 {
      margin-top: 0.75rem !important; }
    .mt-xxl-1 {
      margin-top: 1rem !important; }
    .mt-xxl-2 {
      margin-top: 1.5rem !important; }
    .mt-xxl-3 {
      margin-top: 3rem !important; }
    .mt-xxl-4 {
      margin-top: 3.5rem !important; }
    .mt-xxl-5 {
      margin-top: 4rem !important; }
    .mt-xxl-auto {
      margin-top: auto !important; }
    .me-xxl-0 {
      margin-right: 0 !important; }
    .me-xxl-25 {
      margin-right: 0.25rem !important; }
    .me-xxl-50 {
      margin-right: 0.5rem !important; }
    .me-xxl-75 {
      margin-right: 0.75rem !important; }
    .me-xxl-1 {
      margin-right: 1rem !important; }
    .me-xxl-2 {
      margin-right: 1.5rem !important; }
    .me-xxl-3 {
      margin-right: 3rem !important; }
    .me-xxl-4 {
      margin-right: 3.5rem !important; }
    .me-xxl-5 {
      margin-right: 4rem !important; }
    .me-xxl-auto {
      margin-right: auto !important; }
    .mb-xxl-0 {
      margin-bottom: 0 !important; }
    .mb-xxl-25 {
      margin-bottom: 0.25rem !important; }
    .mb-xxl-50 {
      margin-bottom: 0.5rem !important; }
    .mb-xxl-75 {
      margin-bottom: 0.75rem !important; }
    .mb-xxl-1 {
      margin-bottom: 1rem !important; }
    .mb-xxl-2 {
      margin-bottom: 1.5rem !important; }
    .mb-xxl-3 {
      margin-bottom: 3rem !important; }
    .mb-xxl-4 {
      margin-bottom: 3.5rem !important; }
    .mb-xxl-5 {
      margin-bottom: 4rem !important; }
    .mb-xxl-auto {
      margin-bottom: auto !important; }
    .ms-xxl-0 {
      margin-left: 0 !important; }
    .ms-xxl-25 {
      margin-left: 0.25rem !important; }
    .ms-xxl-50 {
      margin-left: 0.5rem !important; }
    .ms-xxl-75 {
      margin-left: 0.75rem !important; }
    .ms-xxl-1 {
      margin-left: 1rem !important; }
    .ms-xxl-2 {
      margin-left: 1.5rem !important; }
    .ms-xxl-3 {
      margin-left: 3rem !important; }
    .ms-xxl-4 {
      margin-left: 3.5rem !important; }
    .ms-xxl-5 {
      margin-left: 4rem !important; }
    .ms-xxl-auto {
      margin-left: auto !important; }
    .p-xxl-0 {
      padding: 0 !important; }
    .p-xxl-25 {
      padding: 0.25rem !important; }
    .p-xxl-50 {
      padding: 0.5rem !important; }
    .p-xxl-75 {
      padding: 0.75rem !important; }
    .p-xxl-1 {
      padding: 1rem !important; }
    .p-xxl-2 {
      padding: 1.5rem !important; }
    .p-xxl-3 {
      padding: 3rem !important; }
    .p-xxl-4 {
      padding: 3.5rem !important; }
    .p-xxl-5 {
      padding: 4rem !important; }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-xxl-25 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .px-xxl-50 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-xxl-75 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; }
    .px-xxl-1 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-xxl-2 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .px-xxl-3 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-xxl-4 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important; }
    .px-xxl-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important; }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-xxl-25 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .py-xxl-50 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-xxl-75 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important; }
    .py-xxl-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-xxl-2 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .py-xxl-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-xxl-4 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important; }
    .py-xxl-5 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important; }
    .pt-xxl-0 {
      padding-top: 0 !important; }
    .pt-xxl-25 {
      padding-top: 0.25rem !important; }
    .pt-xxl-50 {
      padding-top: 0.5rem !important; }
    .pt-xxl-75 {
      padding-top: 0.75rem !important; }
    .pt-xxl-1 {
      padding-top: 1rem !important; }
    .pt-xxl-2 {
      padding-top: 1.5rem !important; }
    .pt-xxl-3 {
      padding-top: 3rem !important; }
    .pt-xxl-4 {
      padding-top: 3.5rem !important; }
    .pt-xxl-5 {
      padding-top: 4rem !important; }
    .pe-xxl-0 {
      padding-right: 0 !important; }
    .pe-xxl-25 {
      padding-right: 0.25rem !important; }
    .pe-xxl-50 {
      padding-right: 0.5rem !important; }
    .pe-xxl-75 {
      padding-right: 0.75rem !important; }
    .pe-xxl-1 {
      padding-right: 1rem !important; }
    .pe-xxl-2 {
      padding-right: 1.5rem !important; }
    .pe-xxl-3 {
      padding-right: 3rem !important; }
    .pe-xxl-4 {
      padding-right: 3.5rem !important; }
    .pe-xxl-5 {
      padding-right: 4rem !important; }
    .pb-xxl-0 {
      padding-bottom: 0 !important; }
    .pb-xxl-25 {
      padding-bottom: 0.25rem !important; }
    .pb-xxl-50 {
      padding-bottom: 0.5rem !important; }
    .pb-xxl-75 {
      padding-bottom: 0.75rem !important; }
    .pb-xxl-1 {
      padding-bottom: 1rem !important; }
    .pb-xxl-2 {
      padding-bottom: 1.5rem !important; }
    .pb-xxl-3 {
      padding-bottom: 3rem !important; }
    .pb-xxl-4 {
      padding-bottom: 3.5rem !important; }
    .pb-xxl-5 {
      padding-bottom: 4rem !important; }
    .ps-xxl-0 {
      padding-left: 0 !important; }
    .ps-xxl-25 {
      padding-left: 0.25rem !important; }
    .ps-xxl-50 {
      padding-left: 0.5rem !important; }
    .ps-xxl-75 {
      padding-left: 0.75rem !important; }
    .ps-xxl-1 {
      padding-left: 1rem !important; }
    .ps-xxl-2 {
      padding-left: 1.5rem !important; }
    .ps-xxl-3 {
      padding-left: 3rem !important; }
    .ps-xxl-4 {
      padding-left: 3.5rem !important; }
    .ps-xxl-5 {
      padding-left: 4rem !important; }
    .text-xxl-start {
      text-align: left !important; }
    .text-xxl-end {
      text-align: right !important; }
    .text-xxl-center {
      text-align: center !important; } }
  
  @media (min-width: 1200px) {
    .fs-1 {
      font-size: 2rem !important; }
    .fs-2 {
      font-size: 1.714rem !important; }
    .fs-3 {
      font-size: 1.5rem !important; }
    .fs-4 {
      font-size: 1.286rem !important; } }
  
  @media print {
    .d-print-inline {
      display: inline !important; }
    .d-print-inline-block {
      display: inline-block !important; }
    .d-print-block {
      display: block !important; }
    .d-print-grid {
      display: grid !important; }
    .d-print-table {
      display: table !important; }
    .d-print-table-row {
      display: table-row !important; }
    .d-print-table-cell {
      display: table-cell !important; }
    .d-print-flex {
      display: flex !important; }
    .d-print-inline-flex {
      display: inline-flex !important; }
    .d-print-none {
      display: none !important; } }